var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as bsv from "@sensible-contract/bsv";
import { BN } from "@sensible-contract/bsv";
import { createBidTx, createNftAuctionContractTx, createNftForAuctionContractTx, createWithdrawTx, getNftAuctionInput, getNftAuctionUtxo, WitnessOracle, } from "showpay-nft-auction-js";
import { defaultRabinSigner, getSignerConfigs, } from "@sensible-contract/rabin-signer-list";
import * as nftAuctionProto from "showpay-nft-auction-js/lib/contract-proto/nftAuction.proto";
import { getZeroAddress } from "@sensible-contract/sdk-core";
import { createNftGenesisTx, createNftMintTx, createNftTransferTx, createNftUnlockCheckContractTx, getNftGenesisInfo, getNftGenesisInput, getNftInput, NftSigner, selectNftSigners } from "@sensible-contract/nft-js";
import { NFT_UNLOCK_CONTRACT_TYPE } from "@sensible-contract/nft-js/lib/contract-factory/nftUnlockContractCheck";
import { createBuyNftTx, createCancelSellNftTx, createNftSellContractTx, getSellInput } from "showpaynft-sell-js";
import { P2PKH_UNLOCK_SIZE } from "@sensible-contract/sdk-core";
import { createTokenGenesisTx, createTokenIssueTx, createTokenTransferCheckContractTx, createTokenTransferTx, getTokenGenesisInfo, getTokenGenesisInput, getTokenInputs, TokenSigner, } from "@sensible-contract/token-js";
import { TokenTransferCheckFactory, TOKEN_TRANSFER_TYPE, } from "@sensible-contract/token-js/lib/contract-factory/tokenTransferCheck";
import { TxComposer } from "@sensible-contract/tx-composer";
import { toDecimalUnit, signTransaction } from "./utils";
function sleep(time) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, new Promise(function (resolve, reject) {
                    setTimeout(function () {
                        resolve(0);
                    }, time * 1000);
                })];
        });
    });
}
function toTokenAmount(balance, pendingBalance, decimal) {
    var bnAmount = BN.fromString(balance, 10).add(BN.fromString(pendingBalance, 10));
    var tokenAmount = {
        amount: bnAmount.toString(10),
        decimal: decimal,
        uiAmount: toDecimalUnit(bnAmount.toString(10), decimal),
    };
    return tokenAmount;
}
var defaultTokenSigner = new TokenSigner({});
function getTokenSigner(token) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, defaultTokenSigner];
        });
    });
}
var DEFAULT_TX_OPTIONS = {
    onlyEstimateFee: false,
    noBroadcast: false,
};
var defaultNftSigner = new NftSigner({});
var nftSignerMap = {};
export function getNftSigner(rabinPubKeyHashArrayHash) {
    if (rabinPubKeyHashArrayHash === void 0) { rabinPubKeyHashArrayHash = defaultRabinSigner.rabinPubKeyHashArrayHash; }
    return __awaiter(this, void 0, void 0, function () {
        var signerConfigs, _res, signer;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (nftSignerMap[rabinPubKeyHashArrayHash])
                        return [2 /*return*/, nftSignerMap[rabinPubKeyHashArrayHash]];
                    signerConfigs = getSignerConfigs(rabinPubKeyHashArrayHash);
                    return [4 /*yield*/, selectNftSigners(signerConfigs)];
                case 1:
                    _res = _a.sent();
                    signer = new NftSigner({
                        signerConfigs: _res.signers,
                        signerSelecteds: _res.signerSelecteds,
                    });
                    nftSignerMap[rabinPubKeyHashArrayHash] = signer;
                    return [2 /*return*/, signer];
            }
        });
    });
}
var Sensible = /** @class */ (function () {
    function Sensible(provider, wallet) {
        this.provider = provider;
        this.wallet = wallet;
    }
    //bsv
    Sensible.prototype.getBsvBalance = function () {
        return __awaiter(this, void 0, void 0, function () {
            var address, balance;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _a.sent();
                        return [4 /*yield*/, this.provider.getBalance(address)];
                    case 2:
                        balance = _a.sent();
                        return [2 /*return*/, balance];
                }
            });
        });
    };
    Sensible.prototype.mergeBsv = function () {
        return __awaiter(this, void 0, void 0, function () {
            var txComposer, address, utxos, sigResults;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        txComposer = new TxComposer();
                        return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _a.sent();
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 2:
                        utxos = _a.sent();
                        utxos.forEach(function (v, index) {
                            txComposer.appendP2PKHInput({
                                address: new bsv.Address(v.address),
                                txId: v.txId,
                                outputIndex: v.outputIndex,
                                satoshis: v.satoshis,
                            });
                            txComposer.addInputInfo({
                                inputIndex: index,
                            });
                        });
                        txComposer.appendChangeOutput(new bsv.Address(address));
                        return [4 /*yield*/, this.wallet.signTransaction(txComposer.getRawHex(), txComposer.getInputInfos())];
                    case 3:
                        sigResults = _a.sent();
                        txComposer.unlock(sigResults);
                        return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 4:
                        _a.sent();
                        return [2 /*return*/, {
                                utxo: {
                                    txId: txComposer.getTxId(),
                                    outputIndex: 0,
                                    satoshis: txComposer.getOutput(0).satoshis,
                                    address: address,
                                },
                                rawTransaction: txComposer.getRawHex(),
                            }];
                }
            });
        });
    };
    Sensible.prototype.transferBsv = function (to, amount) {
        return __awaiter(this, void 0, void 0, function () {
            var txComposer, address, utxos, sigResults;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        txComposer = new TxComposer();
                        return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _a.sent();
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 2:
                        utxos = _a.sent();
                        utxos.forEach(function (v, index) {
                            txComposer.appendP2PKHInput({
                                address: new bsv.Address(v.address),
                                txId: v.txId,
                                outputIndex: v.outputIndex,
                                satoshis: v.satoshis,
                            });
                            txComposer.addInputInfo({
                                inputIndex: index,
                            });
                        });
                        txComposer.appendP2PKHOutput({
                            address: new bsv.Address(to),
                            satoshis: amount,
                        });
                        txComposer.appendChangeOutput(new bsv.Address(address));
                        return [4 /*yield*/, this.wallet.signTransaction(txComposer.getRawHex(), txComposer.getInputInfos())];
                    case 3:
                        sigResults = _a.sent();
                        txComposer.unlock(sigResults);
                        return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 4:
                        _a.sent();
                        return [2 /*return*/, txComposer.getTxId()];
                }
            });
        });
    };
    Sensible.prototype.transferBsvArray = function (arr, options) {
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var txComposer, address, utxos, sigResults, txid;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        txComposer = new TxComposer();
                        return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _a.sent();
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 2:
                        utxos = _a.sent();
                        utxos.forEach(function (v, index) {
                            txComposer.appendP2PKHInput({
                                address: new bsv.Address(v.address),
                                txId: v.txId,
                                outputIndex: v.outputIndex,
                                satoshis: v.satoshis,
                            });
                            txComposer.addInputInfo({
                                inputIndex: index,
                            });
                        });
                        arr.forEach(function (v) {
                            txComposer.appendP2PKHOutput({
                                address: new bsv.Address(v.to),
                                satoshis: v.amount,
                            });
                        });
                        txComposer.appendChangeOutput(new bsv.Address(address));
                        return [4 /*yield*/, this.wallet.signTransaction(txComposer.getRawHex(), txComposer.getInputInfos())];
                    case 3:
                        sigResults = _a.sent();
                        txComposer.unlock(sigResults);
                        if (!options.noBroadcast) return [3 /*break*/, 4];
                        return [2 /*return*/, { rawtx: txComposer.getRawHex() }];
                    case 4: return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 5:
                        txid = _a.sent();
                        return [2 /*return*/, { txid: txid }];
                }
            });
        });
    };
    Sensible.prototype.transferAllBsv = function (to) {
        return __awaiter(this, void 0, void 0, function () {
            var txComposer, address, utxos, amount, outputIndex, unlockSize, fee, sigResults;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        txComposer = new TxComposer();
                        return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _a.sent();
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 2:
                        utxos = _a.sent();
                        amount = 0;
                        utxos.forEach(function (v, index) {
                            txComposer.appendP2PKHInput({
                                address: new bsv.Address(v.address),
                                txId: v.txId,
                                outputIndex: v.outputIndex,
                                satoshis: v.satoshis,
                            });
                            txComposer.addInputInfo({
                                inputIndex: index,
                            });
                            amount += v.satoshis;
                        });
                        outputIndex = txComposer.appendP2PKHOutput({
                            address: new bsv.Address(to),
                            satoshis: amount,
                        });
                        unlockSize = txComposer.getTx().inputs.length * P2PKH_UNLOCK_SIZE;
                        fee = Math.ceil((txComposer.getTx().toBuffer().length + unlockSize) * txComposer.feeRate);
                        txComposer.getOutput(outputIndex).satoshis -= fee;
                        return [4 /*yield*/, this.wallet.signTransaction(txComposer.getRawHex(), txComposer.getInputInfos())];
                    case 3:
                        sigResults = _a.sent();
                        txComposer.unlock(sigResults);
                        return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 4: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    //nft
    Sensible.prototype.genesisNft = function (_a, options) {
        var nftSigner = _a.nftSigner, totalSupply = _a.totalSupply, genesisWif = _a.genesisWif, utxos = _a.utxos, opreturnData = _a.opreturnData;
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var address, publicKey, wifMap, _i, utxos_1, utxo, network, genesisAddress, balance, fee, txComposer, sigResults, nft, txid;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!nftSigner)
                            nftSigner = defaultNftSigner;
                        return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _b.sent();
                        return [4 /*yield*/, this.wallet.getPublicKey()];
                    case 2:
                        publicKey = _b.sent();
                        wifMap = new Map;
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_1 = utxos; _i < utxos_1.length; _i++) {
                            utxo = utxos_1[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _b.label = 5;
                    case 5:
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        network = new bsv.Address(utxos[0].address).network.alias;
                        genesisAddress = bsv.PrivateKey.fromWIF(genesisWif).toAddress(network).toString();
                        wifMap.set(genesisAddress, genesisWif);
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        fee = createNftGenesisTx.estimateFee({
                            utxoMaxCount: utxos.length,
                            opreturnData: opreturnData
                        });
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        return [4 /*yield*/, createNftGenesisTx({
                                nftSigner: nftSigner,
                                utxos: utxos,
                                genesisPublicKey: publicKey,
                                totalSupply: totalSupply,
                                opreturnData: opreturnData
                            })];
                    case 6:
                        txComposer = (_b.sent()).txComposer;
                        return [4 /*yield*/, signTransaction(txComposer.getRawHex(), txComposer.getInputInfos(), genesisWif, wifMap)];
                    case 7:
                        sigResults = _b.sent();
                        txComposer.unlock(sigResults);
                        nft = getNftGenesisInfo(nftSigner, txComposer.getRawHex());
                        if (!options.noBroadcast) return [3 /*break*/, 8];
                        return [2 /*return*/, { nft: nft, rawtx: txComposer.getRawHex() }];
                    case 8: return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 9:
                        txid = _b.sent();
                        return [2 /*return*/, { nft: nft, txid: txid }];
                }
            });
        });
    };
    Sensible.prototype.mintNft = function (_a, options) {
        var nft = _a.nft, receiverAddress = _a.receiverAddress, mintWif = _a.mintWif, metaTxId = _a.metaTxId, utxos = _a.utxos, opreturnData = _a.opreturnData;
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var address, publicKey, wifMap, _i, utxos_2, utxo, network, mintAddress, _b, genesisInput, genesisContract, balance, fee, nftSigner, txComposer, sigResults, txid;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _c.sent();
                        return [4 /*yield*/, this.wallet.getPublicKey()];
                    case 2:
                        publicKey = _c.sent();
                        if (!receiverAddress)
                            receiverAddress = address;
                        wifMap = new Map;
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_2 = utxos; _i < utxos_2.length; _i++) {
                            utxo = utxos_2[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _c.label = 5;
                    case 5:
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        network = new bsv.Address(utxos[0].address).network.alias;
                        mintAddress = bsv.PrivateKey.fromWIF(mintWif).toAddress(network).toString();
                        wifMap.set(mintAddress, mintWif);
                        return [4 /*yield*/, getNftGenesisInput(this.provider, {
                                codehash: nft.codehash,
                                genesis: nft.genesis,
                                sensibleId: nft.sensibleId,
                            })];
                    case 6:
                        _b = _c.sent(), genesisInput = _b.genesisInput, genesisContract = _b.genesisContract;
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        fee = createNftMintTx.estimateFee({
                            genesisInput: genesisInput,
                            utxoMaxCount: 1,
                            opreturnData: opreturnData,
                        });
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        return [4 /*yield*/, getNftSigner(genesisInput.rabinPubKeyHashArrayHash)];
                    case 7:
                        nftSigner = _c.sent();
                        return [4 /*yield*/, createNftMintTx({
                                nftSigner: nftSigner,
                                genesisInput: genesisInput,
                                genesisContract: genesisContract,
                                utxos: utxos,
                                receiverAddress: receiverAddress,
                                metaTxId: metaTxId,
                                metaOutputIndex: 0,
                            })];
                    case 8:
                        txComposer = (_c.sent()).txComposer;
                        return [4 /*yield*/, signTransaction(txComposer.getRawHex(), txComposer.getInputInfos(), mintWif, wifMap)];
                    case 9:
                        sigResults = _c.sent();
                        txComposer.unlock(sigResults);
                        if (!options.noBroadcast) return [3 /*break*/, 10];
                        return [2 /*return*/, {
                                rawtxs: [txComposer.getRawHex()],
                            }];
                    case 10: return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 11:
                        txid = _c.sent();
                        return [2 /*return*/, { txids: [txid] }];
                }
            });
        });
    };
    Sensible.prototype.transferNft = function (_a, options) {
        var nft = _a.nft, ownerWif = _a.ownerWif, wifMap = _a.wifMap, receiverAddress = _a.receiverAddress, utxos = _a.utxos, opreturnData = _a.opreturnData;
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var address, publicKey, _i, utxos_3, utxo, network, ownerAddress, nftUtxoDetail, nftUtxo, nftInput, balance, fee, nftSigner, txComposer, sigResults, txid;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _b.sent();
                        return [4 /*yield*/, this.wallet.getPublicKey()];
                    case 2:
                        publicKey = _b.sent();
                        if (!receiverAddress)
                            receiverAddress = address;
                        if (!wifMap && utxos) {
                            wifMap = new Map;
                            for (_i = 0, utxos_3 = utxos; _i < utxos_3.length; _i++) {
                                utxo = utxos_3[_i];
                                wifMap.set(utxo.address, utxo.wif);
                            }
                            network = new bsv.Address(utxos[0].address).network.alias;
                            ownerAddress = bsv.PrivateKey.fromWIF(ownerWif).toAddress(network).toString();
                            wifMap.set(ownerAddress, ownerWif);
                        }
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _b.sent();
                        _b.label = 4;
                    case 4:
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        return [4 /*yield*/, this.provider.getNftUtxo(nft.codehash, nft.genesis, nft.tokenIndex)];
                    case 5:
                        nftUtxoDetail = _b.sent();
                        nftUtxo = {
                            txId: nftUtxoDetail.txId,
                            outputIndex: nftUtxoDetail.outputIndex,
                            tokenAddress: nftUtxoDetail.tokenAddress,
                            tokenIndex: nftUtxoDetail.tokenIndex,
                        };
                        return [4 /*yield*/, getNftInput(this.provider, {
                                codehash: nft.codehash,
                                genesis: nft.genesis,
                                nftUtxo: nftUtxo,
                            })];
                    case 6:
                        nftInput = _b.sent();
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        fee = createNftTransferTx.estimateFee({
                            nftInput: nftInput,
                            utxoMaxCount: utxos.length,
                            opreturnData: opreturnData
                        });
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        return [4 /*yield*/, getNftSigner(nftInput.rabinPubKeyHashArrayHash)];
                    case 7:
                        nftSigner = _b.sent();
                        return [4 /*yield*/, createNftTransferTx({
                                nftSigner: nftSigner,
                                nftInput: nftInput,
                                utxos: utxos,
                                receiverAddress: receiverAddress,
                                opreturnData: opreturnData
                            })];
                    case 8:
                        txComposer = (_b.sent()).txComposer;
                        return [4 /*yield*/, signTransaction(txComposer.getRawHex(), txComposer.getInputInfos(), ownerWif, wifMap)];
                    case 9:
                        sigResults = _b.sent();
                        txComposer.unlock(sigResults);
                        if (!options.noBroadcast) return [3 /*break*/, 10];
                        return [2 /*return*/, { rawtx: txComposer.getRawHex() }];
                    case 10: return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 11:
                        txid = _b.sent();
                        return [2 /*return*/, { txid: txid }];
                }
            });
        });
    };
    //nft-sell
    Sensible.prototype.sellNft = function (_a, options) {
        var nft = _a.nft, satoshisPrice = _a.satoshisPrice, sellWif = _a.sellWif, utxos = _a.utxos, opreturnData = _a.opreturnData;
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var address, publicKey, wifMap, _i, utxos_4, utxo, network, sellAddress, fee1, _res, fee, balance, nftUtxoDetail, nftUtxo, nftInput, nftSellRet, sigResults, nftSigner, txComposer, sigResults2, txid1, txid2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _b.sent();
                        return [4 /*yield*/, this.wallet.getPublicKey()];
                    case 2:
                        publicKey = _b.sent();
                        wifMap = new Map;
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_4 = utxos; _i < utxos_4.length; _i++) {
                            utxo = utxos_4[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _b.label = 5;
                    case 5:
                        network = new bsv.Address(utxos[0].address).network.alias;
                        sellAddress = bsv.PrivateKey.fromWIF(sellWif).toAddress(network).toString();
                        wifMap.set(sellAddress, sellWif);
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        fee1 = createNftSellContractTx.estimateFee({
                            utxoMaxCount: utxos.length,
                        });
                        return [4 /*yield*/, this.transferNft({ nft: nft, opreturnData: opreturnData }, { onlyEstimateFee: true })];
                    case 6:
                        _res = _b.sent();
                        fee = fee1 + _res.fee;
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        return [4 /*yield*/, this.provider.getNftUtxo(nft.codehash, nft.genesis, nft.tokenIndex)];
                    case 7:
                        nftUtxoDetail = _b.sent();
                        nftUtxo = {
                            txId: nftUtxoDetail.txId,
                            outputIndex: nftUtxoDetail.outputIndex,
                            tokenAddress: nftUtxoDetail.tokenAddress,
                            tokenIndex: nftUtxoDetail.tokenIndex,
                        };
                        return [4 /*yield*/, getNftInput(this.provider, {
                                codehash: nft.codehash,
                                genesis: nft.genesis,
                                nftUtxo: nftUtxo,
                            })];
                    case 8:
                        nftInput = _b.sent();
                        return [4 /*yield*/, createNftSellContractTx({
                                nftInput: nftInput,
                                satoshisPrice: satoshisPrice,
                                utxos: utxos,
                            })];
                    case 9:
                        nftSellRet = _b.sent();
                        return [4 /*yield*/, signTransaction(nftSellRet.txComposer.getRawHex(), nftSellRet.txComposer.getInputInfos(), sellWif, wifMap)];
                    case 10:
                        sigResults = _b.sent();
                        nftSellRet.txComposer.unlock(sigResults);
                        utxos = [nftSellRet.txComposer.getChangeUtxo()];
                        return [4 /*yield*/, getNftSigner(nftInput.rabinPubKeyHashArrayHash)];
                    case 11:
                        nftSigner = _b.sent();
                        return [4 /*yield*/, createNftTransferTx({
                                nftSigner: nftSigner,
                                nftInput: nftInput,
                                receiverAddress: nftSellRet.sellAddress,
                                utxos: utxos,
                                opreturnData: opreturnData
                            })];
                    case 12:
                        txComposer = (_b.sent()).txComposer;
                        return [4 /*yield*/, signTransaction(txComposer.getRawHex(), txComposer.getInputInfos(), sellWif, wifMap)];
                    case 13:
                        sigResults2 = _b.sent();
                        txComposer.unlock(sigResults2);
                        if (!options.noBroadcast) return [3 /*break*/, 14];
                        return [2 /*return*/, {
                                rawtxs: [nftSellRet.txComposer.getRawHex(), txComposer.getRawHex()],
                            }];
                    case 14: return [4 /*yield*/, this.provider.broadcast(nftSellRet.txComposer.getRawHex())];
                    case 15:
                        txid1 = _b.sent();
                        return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 16:
                        txid2 = _b.sent();
                        return [2 /*return*/, { txids: [txid1, txid2] }];
                }
            });
        });
    };
    Sensible.prototype.sellNft2 = function (_a, options) {
        var nft = _a.nft, satoshisPrice = _a.satoshisPrice, sellWif = _a.sellWif, utxos = _a.utxos;
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var address, publicKey, wifMap, _i, utxos_5, utxo, network, sellAddress, fee, balance, nftUtxoDetail, nftUtxo, nftInput, nftSellRet, sigResults, txid;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _b.sent();
                        return [4 /*yield*/, this.wallet.getPublicKey()];
                    case 2:
                        publicKey = _b.sent();
                        wifMap = new Map;
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_5 = utxos; _i < utxos_5.length; _i++) {
                            utxo = utxos_5[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _b.label = 5;
                    case 5:
                        network = new bsv.Address(utxos[0].address).network.alias;
                        sellAddress = bsv.PrivateKey.fromWIF(sellWif).toAddress(network).toString();
                        wifMap.set(sellAddress, sellWif);
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        fee = createNftSellContractTx.estimateFee({
                            utxoMaxCount: utxos.length,
                        });
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        return [4 /*yield*/, this.provider.getNftUtxo(nft.codehash, nft.genesis, nft.tokenIndex)];
                    case 6:
                        nftUtxoDetail = _b.sent();
                        nftUtxo = {
                            txId: nftUtxoDetail.txId,
                            outputIndex: nftUtxoDetail.outputIndex,
                            tokenAddress: nftUtxoDetail.tokenAddress,
                            tokenIndex: nftUtxoDetail.tokenIndex,
                        };
                        return [4 /*yield*/, getNftInput(this.provider, {
                                codehash: nft.codehash,
                                genesis: nft.genesis,
                                nftUtxo: nftUtxo,
                            })];
                    case 7:
                        nftInput = _b.sent();
                        return [4 /*yield*/, createNftSellContractTx({
                                nftInput: nftInput,
                                satoshisPrice: satoshisPrice,
                                utxos: utxos,
                            })];
                    case 8:
                        nftSellRet = _b.sent();
                        return [4 /*yield*/, signTransaction(nftSellRet.txComposer.getRawHex(), nftSellRet.txComposer.getInputInfos(), sellWif, wifMap)];
                    case 9:
                        sigResults = _b.sent();
                        nftSellRet.txComposer.unlock(sigResults);
                        if (!options.noBroadcast) return [3 /*break*/, 10];
                        return [2 /*return*/, {
                                rawtx: nftSellRet.txComposer.getRawHex(),
                                txid: nftSellRet.txComposer.getTxId,
                                sellAddress: nftSellRet.sellAddress,
                                changeUtxo: nftSellRet.txComposer.getChangeUtxo()
                            }];
                    case 10: return [4 /*yield*/, this.provider.broadcast(nftSellRet.txComposer.getRawHex())];
                    case 11:
                        txid = _b.sent();
                        return [2 /*return*/, { txid: txid, sellAddress: nftSellRet.sellAddress }];
                }
            });
        });
    };
    Sensible.prototype.cancelSellNft = function (_a, options) {
        var nft = _a.nft, ownerWif = _a.ownerWif, utxos = _a.utxos, opreturnData = _a.opreturnData;
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var address, publicKey, wifMap, _i, utxos_6, utxo, network, ownerAddress, nftUtxoDetail, nftUtxo, nftInput, nftUnlockType, fee1, fee2, fee, balance, sellUtxo, _b, sellInput, nftSellContract, nftSellTxComposer, unlockCheckRet, sigResults, nftSigner, txComposer, sigResults2, txid1, txid2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _c.sent();
                        return [4 /*yield*/, this.wallet.getPublicKey()];
                    case 2:
                        publicKey = _c.sent();
                        wifMap = new Map;
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_6 = utxos; _i < utxos_6.length; _i++) {
                            utxo = utxos_6[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _c.label = 5;
                    case 5:
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        network = new bsv.Address(utxos[0].address).network.alias;
                        ownerAddress = bsv.PrivateKey.fromWIF(ownerWif).toAddress(network).toString();
                        wifMap.set(ownerAddress, ownerWif);
                        return [4 /*yield*/, this.provider.getNftUtxo(nft.codehash, nft.genesis, nft.tokenIndex)];
                    case 6:
                        nftUtxoDetail = _c.sent();
                        nftUtxo = {
                            txId: nftUtxoDetail.txId,
                            outputIndex: nftUtxoDetail.outputIndex,
                            tokenAddress: nftUtxoDetail.tokenAddress,
                            tokenIndex: nftUtxoDetail.tokenIndex,
                        };
                        return [4 /*yield*/, getNftInput(this.provider, {
                                codehash: nft.codehash,
                                genesis: nft.genesis,
                                nftUtxo: nftUtxo,
                            })];
                    case 7:
                        nftInput = _c.sent();
                        nftUnlockType = NFT_UNLOCK_CONTRACT_TYPE.OUT_6;
                        fee1 = createNftUnlockCheckContractTx.estimateFee({
                            nftUnlockType: nftUnlockType,
                            utxoMaxCount: utxos.length,
                        });
                        fee2 = createCancelSellNftTx.estimateFee({
                            nftInput: nftInput,
                            utxoMaxCount: 1,
                            opreturnData: opreturnData
                        });
                        fee = fee1 + fee2;
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        return [4 /*yield*/, this.provider.getNftSellUtxo(nft.codehash, nft.genesis, nft.tokenIndex)];
                    case 8:
                        sellUtxo = _c.sent();
                        return [4 /*yield*/, getSellInput(this.provider, {
                                codehash: nft.codehash,
                                genesis: nft.genesis,
                                tokenIndex: nft.tokenIndex,
                                sellUtxo: sellUtxo,
                            })];
                    case 9:
                        _b = _c.sent(), sellInput = _b.sellInput, nftSellContract = _b.nftSellContract;
                        nftSellTxComposer = new TxComposer(new bsv.Transaction(sellInput.txHex));
                        return [4 /*yield*/, createNftUnlockCheckContractTx({
                                nftUnlockType: nftUnlockType,
                                codehash: nft.codehash,
                                nftID: nftInput.nftID,
                                utxos: utxos,
                            })];
                    case 10:
                        unlockCheckRet = _c.sent();
                        return [4 /*yield*/, signTransaction(unlockCheckRet.txComposer.getRawHex(), unlockCheckRet.txComposer.getInputInfos(), ownerWif, wifMap)];
                    case 11:
                        sigResults = _c.sent();
                        unlockCheckRet.txComposer.unlock(sigResults);
                        utxos = [unlockCheckRet.txComposer.getChangeUtxo()];
                        return [4 /*yield*/, getNftSigner(nftInput.rabinPubKeyHashArrayHash)];
                    case 12:
                        nftSigner = _c.sent();
                        return [4 /*yield*/, createCancelSellNftTx({
                                nftSigner: nftSigner,
                                nftInput: nftInput,
                                nftSellContract: nftSellContract,
                                nftSellTxComposer: nftSellTxComposer,
                                nftUnlockCheckContract: unlockCheckRet.unlockCheckContract,
                                nftUnlockCheckTxComposer: unlockCheckRet.txComposer,
                                utxos: utxos,
                                opreturnData: opreturnData
                            })];
                    case 13:
                        txComposer = (_c.sent()).txComposer;
                        return [4 /*yield*/, signTransaction(txComposer.getRawHex(), txComposer.getInputInfos(), ownerWif, wifMap)];
                    case 14:
                        sigResults2 = _c.sent();
                        txComposer.unlock(sigResults2);
                        if (!options.noBroadcast) return [3 /*break*/, 15];
                        return [2 /*return*/, {
                                rawtxs: [unlockCheckRet.txComposer.getRawHex(), txComposer.getRawHex()],
                            }];
                    case 15: return [4 /*yield*/, this.provider.broadcast(unlockCheckRet.txComposer.getRawHex())];
                    case 16:
                        txid1 = _c.sent();
                        return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 17:
                        txid2 = _c.sent();
                        return [2 /*return*/, { txids: [txid1, txid2] }];
                }
            });
        });
    };
    Sensible.prototype.buyNft = function (_a, options) {
        var nft = _a.nft, buyerWif = _a.buyerWif, utxos = _a.utxos, opreturnData = _a.opreturnData;
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var address, publicKey, wifMap, _i, utxos_7, utxo, network, buyerAddress, nftUtxoDetail, nftUtxo, nftInput, sellUtxo, _b, sellInput, nftSellContract, nftUnlockType, fee1, fee2, fee, balance, nftSellTxComposer, unlockCheckRet, sigResults, nftSigner, txComposer, sigResults2, txid1, txid2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _c.sent();
                        return [4 /*yield*/, this.wallet.getPublicKey()];
                    case 2:
                        publicKey = _c.sent();
                        wifMap = new Map;
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_7 = utxos; _i < utxos_7.length; _i++) {
                            utxo = utxos_7[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _c.label = 5;
                    case 5:
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        network = new bsv.Address(utxos[0].address).network.alias;
                        buyerAddress = bsv.PrivateKey.fromWIF(buyerWif).toAddress(network).toString();
                        wifMap.set(buyerAddress, buyerWif);
                        return [4 /*yield*/, this.provider.getNftUtxo(nft.codehash, nft.genesis, nft.tokenIndex)];
                    case 6:
                        nftUtxoDetail = _c.sent();
                        nftUtxo = {
                            txId: nftUtxoDetail.txId,
                            outputIndex: nftUtxoDetail.outputIndex,
                            tokenAddress: nftUtxoDetail.tokenAddress,
                            tokenIndex: nftUtxoDetail.tokenIndex,
                        };
                        return [4 /*yield*/, getNftInput(this.provider, {
                                codehash: nft.codehash,
                                genesis: nft.genesis,
                                nftUtxo: nftUtxo,
                            })];
                    case 7:
                        nftInput = _c.sent();
                        return [4 /*yield*/, this.provider.getNftSellUtxo(nft.codehash, nft.genesis, nft.tokenIndex)];
                    case 8:
                        sellUtxo = _c.sent();
                        return [4 /*yield*/, getSellInput(this.provider, {
                                codehash: nft.codehash,
                                genesis: nft.genesis,
                                tokenIndex: nft.tokenIndex,
                                sellUtxo: sellUtxo,
                            })];
                    case 9:
                        _b = _c.sent(), sellInput = _b.sellInput, nftSellContract = _b.nftSellContract;
                        nftUnlockType = NFT_UNLOCK_CONTRACT_TYPE.OUT_6;
                        fee1 = createNftUnlockCheckContractTx.estimateFee({
                            nftUnlockType: nftUnlockType,
                            utxoMaxCount: utxos.length,
                        });
                        fee2 = createBuyNftTx.estimateFee({
                            nftInput: nftInput,
                            sellInput: sellInput,
                            utxoMaxCount: 1,
                            opreturnData: opreturnData
                        });
                        fee = fee1 + fee2;
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        nftSellTxComposer = new TxComposer(new bsv.Transaction(sellInput.txHex));
                        return [4 /*yield*/, createNftUnlockCheckContractTx({
                                nftUnlockType: nftUnlockType,
                                codehash: nft.codehash,
                                nftID: nftInput.nftID,
                                utxos: utxos,
                            })];
                    case 10:
                        unlockCheckRet = _c.sent();
                        return [4 /*yield*/, signTransaction(unlockCheckRet.txComposer.getRawHex(), unlockCheckRet.txComposer.getInputInfos(), buyerWif, wifMap)];
                    case 11:
                        sigResults = _c.sent();
                        unlockCheckRet.txComposer.unlock(sigResults);
                        utxos = [unlockCheckRet.txComposer.getChangeUtxo()];
                        return [4 /*yield*/, getNftSigner(nftInput.rabinPubKeyHashArrayHash)];
                    case 12:
                        nftSigner = _c.sent();
                        return [4 /*yield*/, createBuyNftTx({
                                nftSigner: nftSigner,
                                nftInput: nftInput,
                                nftSellContract: nftSellContract,
                                nftSellTxComposer: nftSellTxComposer,
                                nftUnlockCheckContract: unlockCheckRet.unlockCheckContract,
                                nftUnlockCheckTxComposer: unlockCheckRet.txComposer,
                                utxos: utxos,
                                opreturnData: opreturnData
                            })];
                    case 13:
                        txComposer = (_c.sent()).txComposer;
                        return [4 /*yield*/, signTransaction(txComposer.getRawHex(), txComposer.getInputInfos(), buyerWif, wifMap)];
                    case 14:
                        sigResults2 = _c.sent();
                        txComposer.unlock(sigResults2);
                        if (!options.noBroadcast) return [3 /*break*/, 15];
                        return [2 /*return*/, {
                                rawtxs: [unlockCheckRet.txComposer.getRawHex(), txComposer.getRawHex()],
                            }];
                    case 15: return [4 /*yield*/, this.provider.broadcast(unlockCheckRet.txComposer.getRawHex())];
                    case 16:
                        txid1 = _c.sent();
                        return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 17:
                        txid2 = _c.sent();
                        return [2 /*return*/, { txids: [txid1, txid2] }];
                }
            });
        });
    };
    //nft-auction
    Sensible.prototype.startNftAuction = function (_a, options) {
        var nft = _a.nft, ownerWif = _a.ownerWif, startBsvPrice = _a.startBsvPrice, endTimeStamp = _a.endTimeStamp, feeAddress = _a.feeAddress, feeRate = _a.feeRate, utxos = _a.utxos, opreturnData = _a.opreturnData;
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var address, publicKey, wifMap, _i, utxos_8, utxo, network, ownerAddress, nftUtxoDetail, nftUtxo, nftInput, fee1, fee2Ret, fee, balance, nftSigner, _b, auctionContractHash, txComposer, sigResults, nftForAuctionAddress, transferNftResult, txid1, txid2;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _c.sent();
                        return [4 /*yield*/, this.wallet.getPublicKey()];
                    case 2:
                        publicKey = _c.sent();
                        wifMap = new Map;
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_8 = utxos; _i < utxos_8.length; _i++) {
                            utxo = utxos_8[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _c.label = 5;
                    case 5:
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        network = new bsv.Address(utxos[0].address).network.alias;
                        ownerAddress = bsv.PrivateKey.fromWIF(ownerWif).toAddress(network).toString();
                        wifMap.set(ownerAddress, ownerWif);
                        return [4 /*yield*/, this.provider.getNftUtxo(nft.codehash, nft.genesis, nft.tokenIndex)];
                    case 6:
                        nftUtxoDetail = _c.sent();
                        nftUtxo = {
                            txId: nftUtxoDetail.txId,
                            outputIndex: nftUtxoDetail.outputIndex,
                            tokenAddress: nftUtxoDetail.tokenAddress,
                            tokenIndex: nftUtxoDetail.tokenIndex,
                        };
                        return [4 /*yield*/, getNftInput(this.provider, {
                                codehash: nft.codehash,
                                genesis: nft.genesis,
                                nftUtxo: nftUtxo,
                            })];
                    case 7:
                        nftInput = _c.sent();
                        fee1 = createNftAuctionContractTx.estimateFee({
                            utxoMaxCount: utxos.length,
                        });
                        return [4 /*yield*/, this.transferNft({ nft: nft, receiverAddress: address, opreturnData: opreturnData, ownerWif: ownerWif, wifMap: wifMap }, { onlyEstimateFee: true })];
                    case 8:
                        fee2Ret = _c.sent();
                        fee = fee1 + fee2Ret.fee;
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        return [4 /*yield*/, getNftSigner(nftInput.rabinPubKeyHashArrayHash)];
                    case 9:
                        nftSigner = _c.sent();
                        return [4 /*yield*/, createNftAuctionContractTx({
                                nftSigner: nftSigner,
                                witnessOracle: new WitnessOracle(),
                                nftInput: nftInput,
                                feeAddress: feeAddress,
                                feeRate: feeRate,
                                senderAddress: address,
                                startBsvPrice: startBsvPrice,
                                endTimeStamp: endTimeStamp,
                                utxos: utxos,
                            })];
                    case 10:
                        _b = _c.sent(), auctionContractHash = _b.auctionContractHash, txComposer = _b.txComposer;
                        return [4 /*yield*/, signTransaction(txComposer.getRawHex(), txComposer.getInputInfos(), ownerWif, wifMap)];
                    case 11:
                        sigResults = _c.sent();
                        txComposer.unlock(sigResults);
                        utxos = [txComposer.getChangeUtxo()];
                        return [4 /*yield*/, createNftForAuctionContractTx(this.provider, {
                                nftInput: nftInput,
                                auctionContractHash: auctionContractHash,
                                utxos: utxos,
                            })];
                    case 12:
                        nftForAuctionAddress = (_c.sent()).nftForAuctionAddress;
                        return [4 /*yield*/, this.transferNft({ nft: nft, receiverAddress: nftForAuctionAddress, utxos: utxos, opreturnData: opreturnData, ownerWif: ownerWif, wifMap: wifMap }, {
                                noBroadcast: true,
                            })];
                    case 13:
                        transferNftResult = _c.sent();
                        if (!options.noBroadcast) return [3 /*break*/, 14];
                        return [2 /*return*/, { rawtxs: [txComposer.getRawHex(), transferNftResult.rawtx] }];
                    case 14: return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 15:
                        txid1 = _c.sent();
                        return [4 /*yield*/, this.provider.broadcast(transferNftResult.rawtx)];
                    case 16:
                        txid2 = _c.sent();
                        return [2 /*return*/, { txids: [txid1, txid2] }];
                }
            });
        });
    };
    Sensible.prototype.bidInNftAuction = function (_a, options) {
        var nft = _a.nft, bidderWif = _a.bidderWif, bsvBidPrice = _a.bsvBidPrice, utxos = _a.utxos, opreturnData = _a.opreturnData;
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var address, publicKey, wifMap, _i, utxos_9, utxo, nftUtxoDetail, nftUtxo, nftInput, nftAuctionUtxo, nftAuctionInput, fee, balance, nftSigner, txComposer, sigResults, txid;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.time('bidStart');
                        return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _b.sent();
                        return [4 /*yield*/, this.wallet.getPublicKey()];
                    case 2:
                        publicKey = _b.sent();
                        wifMap = new Map;
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_9 = utxos; _i < utxos_9.length; _i++) {
                            utxo = utxos_9[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _b.label = 5;
                    case 5:
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        return [4 /*yield*/, this.provider.getNftUtxo(nft.codehash, nft.genesis, nft.tokenIndex)];
                    case 6:
                        nftUtxoDetail = _b.sent();
                        nftUtxo = {
                            txId: nftUtxoDetail.txId,
                            outputIndex: nftUtxoDetail.outputIndex,
                            tokenAddress: nftUtxoDetail.tokenAddress,
                            tokenIndex: nftUtxoDetail.tokenIndex,
                        };
                        return [4 /*yield*/, getNftInput(this.provider, {
                                codehash: nft.codehash,
                                genesis: nft.genesis,
                                nftUtxo: nftUtxo,
                            })];
                    case 7:
                        nftInput = _b.sent();
                        return [4 /*yield*/, getNftAuctionUtxo(this.provider, {
                                nftID: nftInput.nftID,
                            })];
                    case 8:
                        nftAuctionUtxo = _b.sent();
                        return [4 /*yield*/, getNftAuctionInput(this.provider, {
                                nftAuctionUtxo: nftAuctionUtxo,
                            })];
                    case 9:
                        nftAuctionInput = _b.sent();
                        fee = createBidTx.estimateFee({
                            nftAuctionInput: nftAuctionInput,
                            bsvBidPrice: bsvBidPrice,
                            utxoMaxCount: utxos.length,
                            opreturnData: opreturnData
                        });
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        return [4 /*yield*/, getNftSigner(nftInput.rabinPubKeyHashArrayHash)];
                    case 10:
                        nftSigner = _b.sent();
                        return [4 /*yield*/, createBidTx({
                                nftSigner: nftSigner,
                                witnessOracle: new WitnessOracle(),
                                nftAuctionInput: nftAuctionInput,
                                bsvBidPrice: bsvBidPrice,
                                bidderAddress: address,
                                utxos: utxos,
                                opreturnData: opreturnData
                            })];
                    case 11:
                        txComposer = (_b.sent()).txComposer;
                        return [4 /*yield*/, signTransaction(txComposer.getRawHex(), txComposer.getInputInfos(), bidderWif, wifMap)];
                    case 12:
                        sigResults = _b.sent();
                        txComposer.unlock(sigResults);
                        if (!options.noBroadcast) return [3 /*break*/, 13];
                        return [2 /*return*/, { rawtx: txComposer.getRawHex() }];
                    case 13: return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 14:
                        txid = _b.sent();
                        return [2 /*return*/, { txid: txid }];
                }
            });
        });
    };
    Sensible.prototype.withdrawInNftAuction = function (_a, options) {
        var nft = _a.nft, ownerWif = _a.ownerWif, utxos = _a.utxos, opreturnData = _a.opreturnData;
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var address, publicKey, wifMap, _i, utxos_10, utxo, network, ownerAddress, nftUtxoDetail, nftUtxo, nftInput, nftAuctionUtxo, nftAuctionInput, nftUnlockType, fee1, fee2, fee3, fee, balance, auctionLockingScriptBuff, datapart, nftForAuctionRet, sigResults0, unlockCheckRet, sigResults1, nftSigner, txComposer, sigResults2, txid1, txid2, txid3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _b.sent();
                        return [4 /*yield*/, this.wallet.getPublicKey()];
                    case 2:
                        publicKey = _b.sent();
                        wifMap = new Map();
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_10 = utxos; _i < utxos_10.length; _i++) {
                            utxo = utxos_10[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _b.label = 5;
                    case 5:
                        network = new bsv.Address(utxos[0].address).network.alias;
                        ownerAddress = bsv.PrivateKey.fromWIF(ownerWif).toAddress(network).toString();
                        wifMap.set(ownerAddress, ownerWif);
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        return [4 /*yield*/, this.provider.getNftUtxo(nft.codehash, nft.genesis, nft.tokenIndex)];
                    case 6:
                        nftUtxoDetail = _b.sent();
                        nftUtxo = {
                            txId: nftUtxoDetail.txId,
                            outputIndex: nftUtxoDetail.outputIndex,
                            tokenAddress: nftUtxoDetail.tokenAddress,
                            tokenIndex: nftUtxoDetail.tokenIndex,
                        };
                        return [4 /*yield*/, getNftInput(this.provider, {
                                codehash: nft.codehash,
                                genesis: nft.genesis,
                                nftUtxo: nftUtxo,
                            })];
                    case 7:
                        nftInput = _b.sent();
                        return [4 /*yield*/, getNftAuctionUtxo(this.provider, {
                                nftID: nftInput.nftID,
                            })];
                    case 8:
                        nftAuctionUtxo = _b.sent();
                        return [4 /*yield*/, getNftAuctionInput(this.provider, {
                                nftAuctionUtxo: nftAuctionUtxo,
                            })];
                    case 9:
                        nftAuctionInput = _b.sent();
                        nftUnlockType = NFT_UNLOCK_CONTRACT_TYPE.OUT_6;
                        fee1 = createNftForAuctionContractTx.estimateFee({
                            utxoMaxCount: utxos.length,
                        });
                        fee2 = createNftUnlockCheckContractTx.estimateFee({
                            nftUnlockType: nftUnlockType,
                            utxoMaxCount: 1,
                        });
                        fee3 = createWithdrawTx.estimateFee({
                            nftAuctionInput: nftAuctionInput,
                            nftInput: nftInput,
                            utxoMaxCount: 1,
                            opreturnData: opreturnData
                        });
                        fee = fee1 + fee2 + fee3;
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        auctionLockingScriptBuff = nftAuctionInput.lockingScript.toBuffer();
                        datapart = nftAuctionProto.parseDataPart(auctionLockingScriptBuff);
                        datapart.bidBsvPrice = 0;
                        datapart.bidTimestamp = 0;
                        (datapart.bidderAddress =
                            getZeroAddress("mainnet").hashBuffer.toString("hex")),
                            (auctionLockingScriptBuff = nftAuctionProto.updateScript(nftAuctionInput.lockingScript.toBuffer(), datapart));
                        return [4 /*yield*/, createNftForAuctionContractTx(this.provider, {
                                nftInput: nftInput,
                                auctionContractHash: bsv.crypto.Hash.sha256ripemd160(auctionLockingScriptBuff).toString("hex"),
                                utxos: utxos,
                            })];
                    case 10:
                        nftForAuctionRet = _b.sent();
                        return [4 /*yield*/, signTransaction(nftForAuctionRet.txComposer.getRawHex(), nftForAuctionRet.txComposer.getInputInfos(), ownerWif, wifMap)];
                    case 11:
                        sigResults0 = _b.sent();
                        nftForAuctionRet.txComposer.unlock(sigResults0);
                        utxos = [nftForAuctionRet.txComposer.getChangeUtxo()];
                        return [4 /*yield*/, createNftUnlockCheckContractTx({
                                nftUnlockType: nftUnlockType,
                                codehash: nftInput.codehash,
                                nftID: nftInput.nftID,
                                utxos: utxos,
                            })];
                    case 12:
                        unlockCheckRet = _b.sent();
                        return [4 /*yield*/, signTransaction(unlockCheckRet.txComposer.getRawHex(), unlockCheckRet.txComposer.getInputInfos(), ownerWif, wifMap)];
                    case 13:
                        sigResults1 = _b.sent();
                        unlockCheckRet.txComposer.unlock(sigResults1);
                        utxos = [unlockCheckRet.txComposer.getChangeUtxo()];
                        return [4 /*yield*/, getNftSigner(nftInput.rabinPubKeyHashArrayHash)];
                    case 14:
                        nftSigner = _b.sent();
                        return [4 /*yield*/, createWithdrawTx({
                                nftSigner: nftSigner,
                                witnessOracle: new WitnessOracle(),
                                nftInput: nftInput,
                                nftAuctionInput: nftAuctionInput,
                                nftForAuctionContract: nftForAuctionRet.nftForAuctionContract,
                                nftForAuctionTxComposer: nftForAuctionRet.txComposer,
                                nftUnlockCheckContract: unlockCheckRet.unlockCheckContract,
                                nftUnlockCheckTxComposer: unlockCheckRet.txComposer,
                                utxos: utxos,
                                opreturnData: opreturnData
                            })];
                    case 15:
                        txComposer = (_b.sent()).txComposer;
                        return [4 /*yield*/, signTransaction(txComposer.getRawHex(), txComposer.getInputInfos(), ownerWif, wifMap)];
                    case 16:
                        sigResults2 = _b.sent();
                        txComposer.unlock(sigResults2);
                        if (!options.noBroadcast) return [3 /*break*/, 17];
                        return [2 /*return*/, {
                                rawtxs: [
                                    nftForAuctionRet.txComposer.getRawHex(),
                                    unlockCheckRet.txComposer.getRawHex(),
                                    txComposer.getRawHex(),
                                ],
                            }];
                    case 17: return [4 /*yield*/, this.provider.broadcast(nftForAuctionRet.txComposer.getRawHex())];
                    case 18:
                        txid1 = _b.sent();
                        return [4 /*yield*/, this.provider.broadcast(unlockCheckRet.txComposer.getRawHex())];
                    case 19:
                        txid2 = _b.sent();
                        return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 20:
                        txid3 = _b.sent();
                        return [2 /*return*/, { txids: [txid1, txid2, txid3] }];
                }
            });
        });
    };
    //token 
    Sensible.prototype.genesisToken = function (_a, options) {
        var tokenSigner = _a.tokenSigner, tokenName = _a.tokenName, tokenSymbol = _a.tokenSymbol, decimalNum = _a.decimalNum, genesisWif = _a.genesisWif, utxos = _a.utxos, opreturnData = _a.opreturnData;
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var address, publicKey, wifMap, _i, utxos_11, utxo, network, genesisAddress, balance, fee, txComposer, sigResults, token, txid;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!tokenSigner)
                            tokenSigner = defaultTokenSigner;
                        return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _b.sent();
                        return [4 /*yield*/, this.wallet.getPublicKey()];
                    case 2:
                        publicKey = _b.sent();
                        wifMap = new Map;
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _b.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_11 = utxos; _i < utxos_11.length; _i++) {
                            utxo = utxos_11[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _b.label = 5;
                    case 5:
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        network = new bsv.Address(utxos[0].address).network.alias;
                        genesisAddress = bsv.PrivateKey.fromWIF(genesisWif).toAddress(network).toString();
                        wifMap.set(genesisAddress, genesisWif);
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        fee = createTokenGenesisTx.estimateFee({ utxoMaxCount: utxos.length, opreturnData: opreturnData });
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        return [4 /*yield*/, createTokenGenesisTx({
                                tokenSigner: tokenSigner,
                                tokenName: tokenName,
                                tokenSymbol: tokenSymbol,
                                utxos: utxos,
                                genesisPublicKey: publicKey,
                                decimalNum: decimalNum,
                                opreturnData: opreturnData
                            })];
                    case 6:
                        txComposer = (_b.sent()).txComposer;
                        return [4 /*yield*/, signTransaction(txComposer.getRawHex(), txComposer.getInputInfos(), genesisWif, wifMap)];
                    case 7:
                        sigResults = _b.sent();
                        txComposer.unlock(sigResults);
                        token = getTokenGenesisInfo(tokenSigner, txComposer.getRawHex());
                        if (!options.noBroadcast) return [3 /*break*/, 8];
                        return [2 /*return*/, { token: token, rawtx: txComposer.getRawHex() }];
                    case 8: return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 9:
                        txid = _b.sent();
                        return [2 /*return*/, { token: token, txid: txid }];
                }
            });
        });
    };
    Sensible.prototype.issueToken = function (_a, options) {
        var token = _a.token, tokenAmount = _a.tokenAmount, receiverAddress = _a.receiverAddress, _b = _a.allowIncreaseIssues, allowIncreaseIssues = _b === void 0 ? false : _b, issueWif = _a.issueWif, utxos = _a.utxos, opreturnData = _a.opreturnData;
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var address, publicKey, wifMap, _i, utxos_12, utxo, network, issueAddress, _c, genesisInput, genesisContract, balance, fee, tokenSigner, txComposer, sigResults, txid;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.wallet.getAddress()];
                    case 1:
                        address = _d.sent();
                        return [4 /*yield*/, this.wallet.getPublicKey()];
                    case 2:
                        publicKey = _d.sent();
                        if (!receiverAddress)
                            receiverAddress = address;
                        wifMap = new Map;
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _d.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_12 = utxos; _i < utxos_12.length; _i++) {
                            utxo = utxos_12[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _d.label = 5;
                    case 5:
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        network = new bsv.Address(utxos[0].address).network.alias;
                        issueAddress = bsv.PrivateKey.fromWIF(issueWif).toAddress(network).toString();
                        wifMap.set(issueAddress, issueWif);
                        return [4 /*yield*/, getTokenGenesisInput(this.provider, { sensibleId: token.sensibleId })];
                    case 6:
                        _c = _d.sent(), genesisInput = _c.genesisInput, genesisContract = _c.genesisContract;
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        fee = createTokenIssueTx.estimateFee({
                            genesisInput: genesisInput,
                            allowIncreaseIssues: allowIncreaseIssues,
                            utxoMaxCount: utxos.length,
                        });
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        return [4 /*yield*/, getTokenSigner(token)];
                    case 7:
                        tokenSigner = _d.sent();
                        return [4 /*yield*/, createTokenIssueTx({
                                tokenSigner: tokenSigner,
                                genesisInput: genesisInput,
                                genesisContract: genesisContract,
                                utxos: utxos,
                                allowIncreaseIssues: allowIncreaseIssues,
                                receiverAddress: receiverAddress,
                                tokenAmount: tokenAmount,
                            })];
                    case 8:
                        txComposer = (_d.sent()).txComposer;
                        return [4 /*yield*/, signTransaction(txComposer.getRawHex(), txComposer.getInputInfos(), issueWif, wifMap)];
                    case 9:
                        sigResults = _d.sent();
                        txComposer.unlock(sigResults);
                        if (!options.noBroadcast) return [3 /*break*/, 10];
                        return [2 /*return*/, { rawtx: txComposer.getRawHex() }];
                    case 10: return [4 /*yield*/, this.provider.broadcast(txComposer.getRawHex())];
                    case 11:
                        txid = _d.sent();
                        return [2 /*return*/, { txid: txid }];
                }
            });
        });
    };
    Sensible.prototype.transferToken = function (token, receivers, transferWif, utxos, opreturnData, options) {
        if (options === void 0) { options = DEFAULT_TX_OPTIONS; }
        return __awaiter(this, void 0, void 0, function () {
            var tokenSigner, address, wifMap, _i, utxos_13, utxo, network, transferAddress, tokenUtxos, tokenInputs, tokenOutputs, changeAmount, tokenTransferType, fee1, fee2, fee, balance, ret0, sigResults0, ret1, sigResults1, txid0, txid1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getTokenSigner(token)];
                    case 1:
                        tokenSigner = _a.sent();
                        return [4 /*yield*/, this.wallet.getAddress()];
                    case 2:
                        address = _a.sent();
                        wifMap = new Map;
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_13 = utxos; _i < utxos_13.length; _i++) {
                            utxo = utxos_13[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _a.label = 5;
                    case 5:
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        network = new bsv.Address(utxos[0].address).network.alias;
                        transferAddress = bsv.PrivateKey.fromWIF(transferWif).toAddress(network).toString();
                        wifMap.set(transferAddress, transferWif);
                        return [4 /*yield*/, this.mergeToken(token, transferWif, utxos)];
                    case 6:
                        utxos = _a.sent();
                        return [4 /*yield*/, this.provider.getTokenUtxos(token.codehash, token.genesis, address, { cursor: 0, size: 20 })];
                    case 7:
                        tokenUtxos = _a.sent();
                        return [4 /*yield*/, getTokenInputs(this.provider, {
                                tokenSigner: tokenSigner,
                                tokenUtxos: tokenUtxos,
                                codehash: token.codehash,
                                genesis: token.genesis,
                            })];
                    case 8:
                        tokenInputs = _a.sent();
                        tokenOutputs = receivers;
                        changeAmount = tokenInputs
                            .reduce(function (pre, cur) { return pre.add(cur.tokenAmount); }, BN.Zero)
                            .sub(receivers.reduce(function (pre, cur) { return pre.add(BN.fromString(cur.amount, 10)); }, BN.Zero));
                        if (changeAmount.gt(BN.Zero)) {
                            tokenOutputs.push({
                                address: address,
                                amount: changeAmount.toString(10),
                            });
                        }
                        tokenTransferType = TokenTransferCheckFactory.getOptimumType(tokenInputs.length, tokenOutputs.length);
                        fee1 = createTokenTransferCheckContractTx.estimateFee({
                            tokenTransferType: tokenTransferType,
                            utxoMaxCount: utxos.length,
                        });
                        fee2 = createTokenTransferTx.estimateFee({
                            tokenInputs: tokenInputs,
                            tokenOutputs: tokenOutputs,
                            tokenTransferType: tokenTransferType,
                            utxoMaxCount: 1,
                            opreturnData: opreturnData
                        });
                        fee = fee1 + fee2;
                        if (options.onlyEstimateFee)
                            return [2 /*return*/, { fee: fee }];
                        balance = utxos.reduce(function (pre, cur) { return cur.satoshis + pre; }, 0);
                        if (balance < fee)
                            throw "Insufficient Bsv Balance.";
                        return [4 /*yield*/, createTokenTransferCheckContractTx({
                                tokenTransferType: tokenTransferType,
                                tokenInputCount: tokenInputs.length,
                                tokenOutputs: tokenOutputs,
                                tokenID: tokenInputs[0].tokenID,
                                codehash: token.codehash,
                                utxos: utxos,
                            })];
                    case 9:
                        ret0 = _a.sent();
                        return [4 /*yield*/, signTransaction(ret0.txComposer.getRawHex(), ret0.txComposer.getInputInfos(), transferWif, wifMap)];
                    case 10:
                        sigResults0 = _a.sent();
                        ret0.txComposer.unlock(sigResults0);
                        utxos = [
                            {
                                txId: ret0.txComposer.getTxId(),
                                outputIndex: 1,
                                satoshis: ret0.txComposer.getOutput(1).satoshis,
                                address: address,
                            },
                        ];
                        return [4 /*yield*/, createTokenTransferTx({
                                tokenSigner: tokenSigner,
                                tokenInputs: tokenInputs,
                                tokenOutputs: tokenOutputs,
                                transferCheckContract: ret0.transferCheckContract,
                                transferCheckTxComposer: ret0.txComposer,
                                utxos: utxos,
                                opreturnData: opreturnData
                            })];
                    case 11:
                        ret1 = _a.sent();
                        return [4 /*yield*/, signTransaction(ret1.txComposer.getRawHex(), ret1.txComposer.getInputInfos(), transferWif, wifMap)];
                    case 12:
                        sigResults1 = _a.sent();
                        ret1.txComposer.unlock(sigResults1);
                        if (!options.noBroadcast) return [3 /*break*/, 13];
                        return [2 /*return*/, {
                                rawtxs: [ret0.txComposer.getRawHex(), ret1.txComposer.getRawHex()],
                            }];
                    case 13: return [4 /*yield*/, this.provider.broadcast(ret0.txComposer.getRawHex())];
                    case 14:
                        txid0 = _a.sent();
                        return [4 /*yield*/, this.provider.broadcast(ret1.txComposer.getRawHex())];
                    case 15:
                        txid1 = _a.sent();
                        return [2 /*return*/, {
                                txids: [txid0, txid1],
                            }];
                }
            });
        });
    };
    Sensible.prototype.mergeToken = function (token, rootWif, utxos) {
        return __awaiter(this, void 0, void 0, function () {
            var tokenSigner, address, wifMap, _i, utxos_14, utxo, network, rootAddress, _res, i, utxoCount, tokenUtxos, tokenInputs, tokenOutputs, ret0, sigResults0, ret1, sigResults1, txid_0, txid_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, getTokenSigner(token)];
                    case 1:
                        tokenSigner = _a.sent();
                        return [4 /*yield*/, this.wallet.getAddress()];
                    case 2:
                        address = _a.sent();
                        wifMap = new Map;
                        if (!!utxos) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.provider.getUtxos(address)];
                    case 3:
                        utxos = _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        for (_i = 0, utxos_14 = utxos; _i < utxos_14.length; _i++) {
                            utxo = utxos_14[_i];
                            wifMap.set(utxo.address, utxo.wif);
                        }
                        _a.label = 5;
                    case 5:
                        if (!utxos) {
                            throw "have not utxos";
                        }
                        network = new bsv.Address(utxos[0].address).network.alias;
                        rootAddress = bsv.PrivateKey.fromWIF(rootWif).toAddress(network).toString();
                        wifMap.set(rootAddress, rootWif);
                        if (!(utxos.length > 3)) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.mergeBsv()];
                    case 6:
                        _res = _a.sent();
                        utxos = [_res.utxo];
                        _a.label = 7;
                    case 7:
                        i = 0;
                        _a.label = 8;
                    case 8:
                        if (!(i < 100)) return [3 /*break*/, 20];
                        return [4 /*yield*/, this.provider.getTokenBalance(token.codehash, token.genesis, address)];
                    case 9:
                        utxoCount = (_a.sent()).utxoCount;
                        if (utxoCount <= 3)
                            return [3 /*break*/, 20];
                        return [4 /*yield*/, this.provider.getTokenUtxos(token.codehash, token.genesis, address, { cursor: 0, size: 20 })];
                    case 10:
                        tokenUtxos = _a.sent();
                        return [4 /*yield*/, getTokenInputs(this.provider, {
                                tokenSigner: tokenSigner,
                                tokenUtxos: tokenUtxos,
                                codehash: token.codehash,
                                genesis: token.genesis,
                            })];
                    case 11:
                        tokenInputs = _a.sent();
                        tokenOutputs = [
                            {
                                address: address,
                                amount: tokenInputs
                                    .reduce(function (pre, cur) { return pre.add(cur.tokenAmount); }, BN.Zero)
                                    .toString(10),
                            },
                        ];
                        return [4 /*yield*/, createTokenTransferCheckContractTx({
                                tokenTransferType: TOKEN_TRANSFER_TYPE.IN_20_OUT_3,
                                tokenInputCount: tokenInputs.length,
                                tokenOutputs: tokenOutputs,
                                tokenID: tokenInputs[0].tokenID,
                                codehash: token.codehash,
                                utxos: utxos,
                            })];
                    case 12:
                        ret0 = _a.sent();
                        return [4 /*yield*/, signTransaction(ret0.txComposer.getRawHex(), ret0.txComposer.getInputInfos(), rootWif, wifMap)];
                    case 13:
                        sigResults0 = _a.sent();
                        ret0.txComposer.unlock(sigResults0);
                        utxos = [
                            {
                                txId: ret0.txComposer.getTxId(),
                                outputIndex: 1,
                                satoshis: ret0.txComposer.getOutput(1).satoshis,
                                address: address,
                            },
                        ];
                        return [4 /*yield*/, createTokenTransferTx({
                                tokenSigner: tokenSigner,
                                tokenInputs: tokenInputs,
                                tokenOutputs: tokenOutputs,
                                transferCheckContract: ret0.transferCheckContract,
                                transferCheckTxComposer: ret0.txComposer,
                                utxos: utxos,
                            })];
                    case 14:
                        ret1 = _a.sent();
                        return [4 /*yield*/, signTransaction(ret1.txComposer.getRawHex(), ret1.txComposer.getInputInfos(), rootWif, wifMap)];
                    case 15:
                        sigResults1 = _a.sent();
                        ret1.txComposer.unlock(sigResults1);
                        return [4 /*yield*/, this.provider.broadcast(ret0.txComposer.getRawHex())];
                    case 16:
                        txid_0 = _a.sent();
                        return [4 /*yield*/, this.provider.broadcast(ret1.txComposer.getRawHex())];
                    case 17:
                        txid_1 = _a.sent();
                        return [4 /*yield*/, sleep(2)];
                    case 18:
                        _a.sent();
                        utxos = [
                            {
                                txId: ret1.txComposer.getTxId(),
                                outputIndex: 1,
                                satoshis: ret1.txComposer.getOutput(1).satoshis,
                                address: address,
                            },
                        ];
                        _a.label = 19;
                    case 19:
                        i++;
                        return [3 /*break*/, 8];
                    case 20: return [2 /*return*/, utxos];
                }
            });
        });
    };
    return Sensible;
}());
export { Sensible };

import * as bsv from "@sensible-contract/bsv";
import { BN, Message } from "@sensible-contract/bsv";
export function fromDecimalUnit(value, decimal) {
    var negative = value.substring(0, 1) === "-"; //
    if (negative) {
        value = value.substring(1);
    }
    var base = BN.fromNumber(Math.pow(10, decimal));
    var comps = value.split(".");
    if (comps.length > 2) {
        throw new Error("while converting number ".concat(value, " to non decimal value, too many decimal points"));
    }
    var whole = comps[0] || "0";
    var fraction = comps[1] || "0";
    if (fraction.length > decimal) {
        throw new Error("while converting number ".concat(value, " to non decimal value, too many decimal places"));
    }
    while (fraction.length < decimal) {
        fraction += "0";
    }
    var bnWhole = new BN(whole);
    var bnFraction = new BN(fraction);
    var bnValue = bnWhole.mul(base).add(bnFraction);
    if (negative) {
        bnValue = bnValue.mul(BN.Minus1);
    }
    return new BN(bnValue.toString(10), 10);
}
export function signTransaction(txHex, inputInfos, rootWif, wifMap) {
    var tx = new bsv.Transaction(txHex);
    var sigResults = inputInfos.map(function (v) {
        var address = v.address;
        var privateKey;
        if (address != undefined) {
            privateKey = bsv.PrivateKey.fromWIF(wifMap.get(address));
        }
        else {
            privateKey = bsv.PrivateKey.fromWIF(rootWif);
        }
        var sighash = bsv.Transaction.Sighash.sighash(tx, v.sighashType, v.inputIndex, new bsv.Script(v.scriptHex), new bsv.crypto.BN(v.satoshis)).toString("hex");
        var sig = bsv.crypto.ECDSA.sign(Buffer.from(sighash, "hex"), privateKey, "little")
            .set({
            nhashtype: v.sighashType,
        })
            .toString();
        return { sig: sig, publicKey: privateKey.publicKey.toString() };
    });
    return sigResults;
}
export function toDecimalUnit(value, decimal) {
    var bnValue = BN.fromString(value, 10);
    var base = BN.fromNumber(Math.pow(10, decimal));
    var negative = bnValue.lt(BN.Zero);
    if (negative) {
        bnValue = bnValue.mul(BN.Minus1);
    }
    var fraction = bnValue.mod(base).toString(10);
    while (fraction.length < decimal) {
        fraction = "0".concat(fraction);
    }
    var whole = bnValue.div(base).toString(10);
    var result = "".concat(whole).concat(fraction == "0" ? "" : ".".concat(fraction));
    if (negative) {
        result = "-".concat(result);
    }
    return result;
}
export function toSatoshis(bsv) {
    return fromDecimalUnit(bsv.toString(), 8).toNumber();
}
export function fromSatoshis(satoshis) {
    return parseFloat(toDecimalUnit(satoshis.toString(), 8));
}
export function verifyMessage(message, address, signature) {
    try {
        return Message.verify(message, address, signature);
    }
    catch (e) {
        return false;
    }
}

/*
 * @Author: ohosanna
 * @Date: 2020-03-18 14:45:22
 * @Last Modified by: ohosanna
 * @Last Modified time: 2020-04-28 11:37:08
 */
// interface PopupOptionsTypes {
//   title?: string;
//   message: string;
//   className?: string;
//   buttonText?: string;
//   buttonText2?: string;
//   buttonUrl?: string;
//   buttonUrl2?: string;
//   buttonAction?: Function;
//   buttonAction2?: Function;
//   wrapper?: HtmlElement
// }

class Popup {
  popupWrapper;
  constructor() {
    const popupEl = document.createElement("div");
    popupEl.setAttribute("id", "showmoney-popup");
    document.body.appendChild(popupEl);
    this.popupWrapper = popupEl;
  }
  info(options) {
    this.show("info", options);
  }
  error(options) {
    this.show("error", options);
  }
  confirm(options) {
    if (!options.buttonAction) {
      options.buttonAction = () => {
        this.close();
      };
    }
    this.show("confirm", options);
  }
  show(type = "info", options) {
    const popupDom = this.generatePopupContent(options);
    if (options.wrapper) {
      this.popupWrapper = options.wrapper;
    }
    this.popupWrapper.appendChild(popupDom);
    this.popupWrapper.className = type + "-popup" + " active";
  }
  close() {
    this.popupWrapper.className = "";
    this.popupWrapper.innerHTML = "";
  }
  generatePopupContent(options) {
    const box = document.createElement("div");
    box.className =
      "sm-popup-box " + (options.className ? options.className : "");

    // header
    const popupHeader = document.createElement("header");
    popupHeader.className = "sm-popup-header";
    const popupTitle = document.createElement("h2");
    popupTitle.className = "sm-popup-title";
    if (options.title) {
      popupTitle.innerHTML = options.title;
      popupHeader.appendChild(popupTitle);
    }

    // content
    const popupContent = document.createElement("div");
    popupContent.className = "sm-popup-content";
    popupContent.innerHTML = options.message;

    // footer
    const popupFooter = document.createElement("footer");
    popupFooter.className = "sm-popup-footer";
    if (options.buttonText) {
      const button = document.createElement("button");
      button.className = "sm-popup-btn sm-popup-btn1";
      button.innerText = options.buttonText;
      if (options.buttonUrl) {
        const url = options.buttonUrl;
        button.onclick = () => {
          window.open(url, "_blank");
        };
      }
      if (options.buttonAction) {
        button.onclick = () => {
          if (typeof options.buttonAction === "function") {
            options.buttonAction();
          }
        };
      }
      popupFooter.appendChild(button);
    }
    if (options.buttonText2) {
      const button2 = document.createElement("button");
      button2.className = "sm-popup-btn sm-popup-btn2";
      button2.innerText = options.buttonText2;
      if (options.buttonUrl2) {
        const url = options.buttonUrl2;
        button2.onclick = () => {
          window.open(url, "_blank");
        };
      }
      if (options.buttonAction2) {
        button2.onclick = () => {
          if (typeof options.buttonAction2 === "function") {
            options.buttonAction2();
          }
        };
      }
      popupFooter.appendChild(button2);
    }

    box.appendChild(popupHeader);
    box.appendChild(popupContent);
    box.appendChild(popupFooter);
    return box;
  }
}

export default new Popup();

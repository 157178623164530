var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-frame" }, [
      _c("div", { attrs: { id: "showmoney-popup" } }),
      _c("span", { staticStyle: { display: "none" } }, [_vm._v("v1.0.10.13")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="main-frame">
    <div id="showmoney-popup"></div>
    <span style="display: none">v1.0.10.13</span>
  </div>
</template>
<script>
import PostmessageClient from "postmessage-client";
import ShowmoneyMetanetSDK from "showmoney-metanet-sdk";
import { MetaSVProvider, SensiblequeryProvider } from "showpay-providers";
// import { SensiblequeryProvider } from '@sensible-contract/providers'
import { LocalWallet } from "@sensible-contract/wallets";
import { Sensible } from "@sensible-contract/sensible-sdk-v2";
import { NftSigner } from "@sensible-contract/nft-js";
// import { Sensible } from '@sensible-contract/sdk-extension-demo'
import CryptoJS from "crypto-js";
import Popup from "../components/popup";
import { authCheck } from "@/api/authorizeLogin_api.js";
import { Decimal } from "decimal.js-light";
import {
  checkUserToken,
  getriskinfo,
  uploadPayment,
  getEnCryptedMnemonic,
  getInviteCode,
} from "@/api/api.js";
import { useTestNet, walletApi } from "../common/js/baseApi";

const RIPEMD128 = require("ripemd128-js");
const Bip39 = require("bip39");
const bsv = require("bsv");
const ECIES = require("bsv/ecies");
const Mnemonic = require("bsv/mnemonic");
import { useMainNet } from "../common/js/baseApi";

const parentMessageClient = new PostmessageClient(window.parent);
const selfMessageClient = new PostmessageClient(window);
const sensibleFeeb = 0.5;
const metaIdTag = process.env.VUE_APP_IDtags;
const openIdKey = process.env.VUE_APP_OPENIDKEY;
const metasvServiceSecret =
  "KxSQqTxhonc5i8sVGGhP1cMBGh5cetVDMfZjQdFursveABTGVbZD";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default {
  data() {
    return {
      BASE_URL: process.env.VUE_APP_AUTH_URL,
      MetanetSDK: null,
      metaId: null,
      userData: JSON.parse(localStorage.getItem("localuserData")),
      authSettings: {},
      riskLimit: null,
      checkOnlyCache: {},
      protocolWhiteList: ["SimpleArticleDonate"],
      lastCheckTokenTime: 0,
    };
  },
  computed: {
    isLogined() {
      return localStorage.getItem("__Secure-SSID");
      // return localStorage.getItem('localuserData')
    },
  },
  methods: {
    getOptions(params) {
      this.authSettings = params.payload;
    },
    async authCheck(token) {
      if (!token || !this.userData) return false;
      // const authRes = await authCheck(token)
      if (Date.now() - this.lastCheckTokenTime < 60000) {
        return true;
      } else {
        this.lastCheckTokenTime = Date.now();
      }
      return authCheck(token)
        .then((res) => {
          console.log("authCheck:", res);
          this.lastCheckTokenTime = 0;
          const userName =
            this.userData.register === "email"
              ? this.userData.email
              : this.userData.phone;
          if (
            res.client_id === this.authSettings.clientId &&
            res.user_name === userName
          ) {
            return true;
          } else {
            this.logout();
            return false;
          }
        })
        .catch(() => {
          this.lastCheckTokenTime = 0;
          this.sendError(204, "Network error");
          throw new Error("Network error");
          // return false;
        });
    },
    async makeTx(params) {
      const payload = params.payload;
      try {
        await this.MetanetSDK.getUtxoFromApi(true);
        const tx = await this.MetanetSDK.makeTx({
          outputs: payload.payToList,
        });

        this.receiveCallback(params.payload.handlerId, {
          code: 200,
          status: "success",
          data: {
            rawTx: tx.toString(),
            fee: tx._fee,
          },
        });
      } catch (error) {
        this.generateRes(204, "error", {
          message: error.message,
        });
        this.receiveCallback(params.payload.handlerId, {
          code: 204,
          status: "error",
          data: error,
        });
      }
    },

    async createNode(params) {
      Popup.close();
      if (!this.isLogined) {
        return this.handleNotLogined();
      }
      const accessToken =
        params.payload && params.payload.accessToken
          ? params.payload.accessToken
          : null;
      if (!accessToken) return this.handleNotLogined();
      const isAuthed = await this.authCheck(accessToken);
      console.log("isAuthed:", isAuthed);
      if (!isAuthed) {
        Popup.close();
        this.handleNotLogined({
          message: this.$t("loginLostTip"),
          useCurrentWindow: true,
        });
        return false;
      }
      if (params.payload.handlerId && !accessToken) {
        return this.receiveNotLogin(params.payload.handlerId);
      }
      if (!this.MetanetSDK) {
        try {
          this.initSDK();
        } catch (error) {
          this.sendError(204, error.message || "SDK init fault");
        }
      }
      try {
        await this.initLimit(accessToken);
      } catch (err) {
        this.sendError(205, err.message);
      }
      var options = {
        ...params.payload,
        useFeeb: this.riskLimit?.fee ? +this.riskLimit.fee : 1,
      };
      if (options.checkOnly) {
        if (this.MetanetSDK._utxos.length > 0) {
          options.utxos = this.MetanetSDK._utxos;
        }
      }
      console.log("options", options);
      var handlerId = options.handlerId;
      const that = this;
      const reqp = Object.assign({}, options, {
        needConfirm: true,
      });
      if (options.isWebot) {
        this.MetanetSDK.createWebotProtocolNode(options)
          .then(async (res) => {
            console.log("createWebotProtocolNode", res);
            res.handlerId = handlerId;
            // if (res.code === 200) {
            //   parentMessageClient.send("success.create-node", res);
            // }
            if (res.code === 205) {
              // 数据脱敏
              let utxos = res.data.utxos;
              if (Array.isArray(utxos)) {
                utxos = utxos.map((utxo) => {
                  delete utxo.wif;
                  return utxo;
                });
                res.data.utxos = utxos;
              }
              // 只获取结果，不广播
              if (options.checkOnly) {
                that.receiveCallback(handlerId, res);
                // parentMessageClient.send('confirm.create-node', res)
              }
            } else if (res.code === 200) {
              that.receiveCallback(handlerId, res);
            } else {
              parentMessageClient.send("error.create-node", res);
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.code === 203) {
              parentMessageClient.send("error.not-enough-money", error);
            } else {
              parentMessageClient.send("error.create-node", error);
            }
          });
      } else {
        this.MetanetSDK.createProtocolNode(reqp)
          .then(async (res) => {
            console.log("createProtocolNode", res);
            res.handlerId = handlerId;
            // if (res.code === 200) {
            //   parentMessageClient.send("success.create-node", res);
            // }
            if (res.code === 205) {
              // 数据脱敏
              let utxos = res.data.utxos;
              if (Array.isArray(utxos)) {
                utxos = utxos.map((utxo) => {
                  delete utxo.wif;
                  return utxo;
                });
                res.data.utxos = utxos;
              }
              // 只获取结果，不广播
              if (options.checkOnly) {
                that.receiveCallback(handlerId, res);
                // parentMessageClient.send('confirm.create-node', res)
              } else {
                let txAmounts = [];
                for (const item of res.data.txDetai) {
                  txAmounts.push(item.amount);
                }
                if (
                  !that.riskLimit.popupsPay ||
                  typeof params.payload.needConfirm === "undefined"
                ) {
                  params.payload.needConfirm = true;
                }
                if (!params.payload.needConfirm) {
                  if (that.protocolWhiteList.indexOf(options.nodeName) < 0) {
                    await that._uploadPayment(
                      accessToken,
                      res.data.usedAmount,
                      params.payload.payTo,
                      txAmounts
                    );
                  }
                  return that.handleConfirmedCreateNode({
                    payload: {
                      handlerId: res.handlerId,
                      nodeName: res.data.nodeName,
                      isNotConfirm: false,
                      transactionTask: res.data.transactionTask,
                    },
                  });
                }
                Popup.close();
                let amount =
                  res.data.payCurrency === "usd"
                    ? res.data.usedAmountCent
                    : res.data.usedAmount;
                let unit = res.data.payCurrency === "usd" ? "￠" : "SATS";
                console.log("optionsoptions", options);
                let message = '<div class="amount-btn">';
                if (res.data.payCurrency !== "usd") {
                  if (+amount >= 1000000) {
                    if (this.$store.state.chain === "BSV") {
                      unit = "BSV";
                    } else {
                      unit = "SPACE";
                    }
                    amount = new Decimal(amount).div(Math.pow(10, 8));
                  }
                }
                message += [amount, unit].join(" ");
                if (res.data.payCurrency === "usd") {
                  message += "<span>≈ " + res.data.usedAmount + " sats</span>";
                }
                message += "</div>";
                console.log("res.data.usedAmount", res);
                if (amount > 20000 && options.payTo.length) {
                  if (options.tip) {
                    options.tip = options.tip.split(/\r\n/)[1];
                  } else {
                    options.tip = ``;
                  }
                  options.tip = options.tip.split(/\r\n/)[1];
                } else if (amount > 20000 && !options.payTo.length) {
                  options.tip = ``;
                }
                if (options.tip) {
                  message += "<p class='tip'>" + options.tip + "</p>";
                }
                console.log(message);
                Popup.confirm({
                  title: "支付上链费",
                  // message: '<div class="amount-btn">' + payload.data.usedAmount + ' SATS</div><div class="checkbox-group"><input type="checkbox" id="is-not-confirm" /><label for="is-not-confirm">下次自动支付</div>',
                  message,
                  buttonText: "取消",
                  buttonText2: "确认支付",
                  className: "confirm-create",
                  wrapper: document.getElementById("showmoney-popup"),
                  buttonAction: () => {
                    parentMessageClient.send("close.create-node", res);
                    Popup.close();
                  },
                  buttonAction2: async () => {
                    parentMessageClient.send("loading");
                    // 上传支付信息
                    if (that.protocolWhiteList.indexOf(options.nodeName) < 0) {
                      that
                        ._uploadPayment(
                          accessToken,
                          res.data.usedAmount,
                          params.payload.payTo,
                          txAmounts
                        )
                        .then(() => {
                          that.handleConfirmedCreateNode({
                            payload: {
                              handlerId: res.handlerId,
                              nodeName: res.data.nodeName,
                              isNotConfirm: false,
                              transactionTask: res.data.transactionTask,
                              buzzcard: res.data.buzzcard,
                              amount: res.data.usedAmount,
                            },
                          });
                        })
                        .catch((err) => {
                          return that.sendError(205, err.message);
                        });
                    } else {
                      that.handleConfirmedCreateNode({
                        payload: {
                          handlerId: res.handlerId,
                          nodeName: res.data.nodeName,
                          isNotConfirm: false,
                          transactionTask: res.data.transactionTask,
                        },
                      });
                    }
                  },
                });
                parentMessageClient.send("confirm.create-node");
              }
            } else {
              parentMessageClient.send("error.create-node", res);
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.code === 203) {
              parentMessageClient.send("error.not-enough-money", error);
            } else {
              parentMessageClient.send("error.create-node", error);
            }
          });
      }
    },
    async _uploadPayment(accessToken, amount, pay_to = [], txAmounts = []) {
      return uploadPayment({
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        create_time: parseInt(new Date().getTime()),
        amount,
        pay_to,
        user_token: accessToken,
        txAmounts,
      });
    },
    async initLimit(accessToken) {
      let res = await getriskinfo({
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        user_token: accessToken,
      });
      return (this.riskLimit = res);
    },
    handleConfirmedCreateNode(params) {
      this.MetanetSDK.resumeTransaction(
        params.payload.transactionTask,
        params.payload?.amount,
        params.payload?.buzzcard
      )
        .then((res) => {
          res.handlerId = params.payload.handlerId;
          parentMessageClient.send("success.create-node", res);
          Popup.close();
        })
        .catch((error) => {
          // console.error(error)
          if (error) {
            if (error.data) {
              return this.sendError(204, error.data.message);
            } else {
              return this.sendError(204, error.message);
            }
          }
        });

      // const transaction = params.payload.transactionHex
      // this.MetanetSDK.sendTx(transaction).then(res => {
      //   res.handlerId = params.payload.handlerId
      //   parentMessageClient.send('success.create-node', res)
      // }).catch(error => {
      //   console.log(error)
      // })
    },
    decrypt(word, keyStr) {
      var decrypt = CryptoJS.AES.decrypt(
        word,
        CryptoJS.enc.Utf8.parse(keyStr),
        {
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7,
        }
      );
      return decrypt.toString(CryptoJS.enc.Utf8);
    },
    async getPrivateKey() {
      if (!this.isLogined) return;
      const accessKey = localStorage.getItem("__Secure-SSID");
      const evidenceToken = this.decrypt(
        accessKey,
        this.userData.pk2.slice(0, 32)
      );
      const result = await getEnCryptedMnemonic({
        userType: this.userData.register,
        phone: this.userData.register === "phone" ? this.userData.phone : "",
        email: this.userData.register === "email" ? this.userData.email : "",
        token: this.userData.token,
      });
      if (result.enCryptedMnemonic) {
        const isTestnet = useTestNet();
        const deMnemonic = this.$utils.aesDescryptMem(
          result.enCryptedMnemonic,
          evidenceToken
        );
        return bsv.Mnemonic.fromString(deMnemonic)
          .toHDPrivateKey(
            "",
            isTestnet ? bsv.Networks.testnet.name : bsv.Networks.mainnet.name
          )
          .deriveChild(
            this.userData.tag === "new" ? "m/44'/10001'/0'" : "m/44'/145'/0'"
          );
      } else {
        const key = this.createMasterHdPrivateKey(
          this.userData.register === "email"
            ? this.userData.email
            : this.userData.phone,
          evidenceToken,
          this.userData.pk2,
          this.userData.tag
        );
        // console.log(key)
        return key;
      }
    },
    createMasterHdPrivateKey(phone, password, serverHex, userFlag = "old") {
      // 转换大数字，字符串，数字和缓冲区
      const ppBuffer = bsv.deps.Buffer(phone + "/" + password);
      const ppHex = bsv.crypto.Hash.sha256(ppBuffer).toString("hex");
      let hex;
      const rip128 = RIPEMD128.RIPEMD128;
      // 生成 hax
      if (userFlag === "new") {
        hex = bsv.deps.Buffer.from(ppHex + serverHex, "hex");
        hex = rip128(hex.toString("hex")).toString();
      } else {
        hex = bsv.deps.Buffer.from(ppHex + serverHex);
        hex = bsv.crypto.Hash.sha256sha256(hex).toString("hex");
      }
      // 助记词
      const m = Bip39.entropyToMnemonic(hex);
      // 根据用户类型生成 12 助记词或 24 助记词
      const path = userFlag === "new" ? "m/44'/10001'/0'" : "m/44'/145'/0'";
      const puk = Mnemonic.fromString(m)
        .toHDPrivateKey(
          "",
          useTestNet() ? bsv.Networks.testnet : bsv.Networks.mainnet
        )
        .deriveChild(path);
      return puk;
    },
    async initSDK() {
      if (!this.userData) {
        return this.sendError(201, "User authentication failed");
      }
      console.log("initSDK userData:", this.userData);
      const privateKey = await this.getPrivateKey();
      const userData = this.userData;
      const accessKey = localStorage.getItem("__Secure-SSID");
      const userDataDef = {
        userType: userData.register,
        userName:
          userData.register === "email" ? userData.email : userData.phone,
        nickName: userData.name,
        pk2: userData.pk2,
        token: userData.token,
        lastLoginTime: userData.lastLoginTime,
        phone: userData.phone,
        email: userData.email,
        metaId: userData.metaId,
        secureKey: accessKey,
        path: userData.path,
      };

      console.log("在用网络", this.$store.state.chain, useTestNet());
      this.MetanetSDK = await ShowmoneyMetanetSDK.create({
        xprivKey: privateKey.xprivkey,
        userData: userDataDef,
        // isProd: Boolean(+process.env.VUE_APP_USEMAINNET),
        // debug: process.env.VUE_APP_ENV !== "production",
        isTestnet: useTestNet(),
        chain: this.$store.state.chain,
        debug: true,
        isProd: false,
      });
      console.log("this.MetanetSDK.metaIdInfo:", this.MetanetSDK.metaIdInfo);
      // 测试
      /* this.nftStartAuction({ */
      /* payload: { */
      /* checkOnly: true, */
      /* nft: { */
      /* codehash: '0d0fc08db6e27dc0263b594d6b203f55fb5282e2', */
      /* genesis: 'c5e90fab956d4698bf0703a1be69dfba6fbe6934', */
      /* genesisTxid: '045e5e8a012b31c3abba33fde83f1be8a5fc92678fe86f168b23dbd27c5bdc36', */
      /* tokenIndex: '19' */
      /* }, */
      /* 'startBsvPrice': 1000, */
      /* 'endTimeStamp': 1640793600000, */
      /* 'feeAddress': '19NeJJM6eEa3bruYnqkTA4Cp6VvdFGSepd', */
      /* 'feeAmount': 1000, */
      /* 'useFeeb': 0.5 */
      /* } */
      /* }) */
      parentMessageClient.send("sdk-loaded", {});
      if (this.MetanetSDK.metaIdInfo.metaId) {
        console.log("loaded", this.MetanetSDK);
        this.metaId = this.MetanetSDK.metaIdInfo.metaId;
      }
    },
    logout(params) {
      if (!params) {
        localStorage.clear();
        localStorage.removeItem("__Secure-SSID");
        return;
      }
      if (params.payload.outType === "all") {
        // localStorage.clear();
      }
      console.log("删除密码");
      localStorage.removeItem("__Secure-SSID");
      var handlerId = params.payload.handlerId;
      if (handlerId) {
        this.receiveCallback(handlerId, {});
      }
    },
    handleNotLogined(params) {
      const payload = {
        error: "not logged in",
        popup: {
          title: "VisionMoney",
          message:
            params?.message ||
            "未登录 VisionMoney 账号，要继续操作，请先登录。",
          buttonText: "注册",
          buttonUrl: `${this.BASE_URL}/Matesign?response_type=code&client_id=${this.authSettings.clientId}&redirect_uri=${this.authSettings.redirectUri}&scope=app&from=${this.authSettings.redirectUri}`,
          buttonText2: "登录",
          buttonUrl2: `${this.BASE_URL}/userLogin?response_type=code&client_id=${this.authSettings.clientId}&redirect_uri=${this.authSettings.redirectUri}&scope=app&from=${this.authSettings.redirectUri}`,
          ...params,
        },
      };
      // const parentMessage = new postmessageClient(window.parent)
      parentMessageClient.send("error.not-logged-in", payload);
    },
    receiveNotLogin(handlerId) {
      const res = {
        code: 201,
        status: "error",
        data: {
          message: "User authentication failed",
        },
      };
      // console.log(handlerId)
      this.receiveCallback(handlerId, res);
    },
    async getUserInfo(params) {
      console.log("getUserInfo");
      console.time();
      if (!this.isLogined) {
        return this.receiveNotLogin(params.payload.handlerId);
      }
      const accessToken =
        params.payload && params.payload.accessToken
          ? params.payload.accessToken
          : null;
      const isAuthed = await this.authCheck(accessToken);
      // 无回调，则弹出登录框
      if (!params.payload.handlerId && (!accessToken || !isAuthed)) {
        return this.receiveNotLogin(params.payload.handlerId);
      }
      // if (!this.userData) return this.handleNotLogined()
      // console.log(params)
      // var metaId = this.userData.metaId
      if (this.metaId === "") {
        const res = {
          code: 202,
          status: "error",
          data: {
            message:
              "This user has not generated MateID. Go to VisionMoney to create",
          },
        };
        return this.receiveCallback(params.payload.handlerId, res);
      }
      if (this.metaId === undefined || this.metaId === null) {
        const res = {
          code: 203,
          status: "error",
          data: {
            message: "getOwnShowAccount failed,refresh current page,please!",
          },
        };
        return this.receiveCallback(params.payload.handlerId, res);
      }
      const loginName =
        this.userData.register === "phone"
          ? this.userData.phone
          : this.userData.email;
      const timestamp = Date.now();
      console.log(loginName, openIdKey);

      const publicKey = bsv.PublicKey.fromHex(openIdKey);
      const ecies = ECIES().publicKey(publicKey);
      const openId = ecies
        .encrypt([loginName, timestamp].join("_"))
        .toString("hex");

      console.log("openId: ", openId);

      this.receiveCallback(params.payload.handlerId, {
        code: 200,
        status: "success",
        data: {
          ...this.MetanetSDK.metaIdInfo,
          openId: openId,
        },
      });
      // var userInfo
      // await this.getShowDidInfo(this.metaId, params)
      // .then(res => {
      //   userInfo = res
      //   console.log(params)
      //   var handlerId = params.payload ? params.payload.handlerId : null
      //   console.log(handlerId)
      //   if (handlerId) {
      //     userInfo.handlerId = handlerId
      //   }
      //   console.log(userInfo)
      //   parentMessageClient.send('receive-callback', userInfo)
      // })
      console.log("getUserinfo end");
      console.timeLog();
      console.timeEnd();
    },
    async getShowDidInfo(metaId, params) {
      // eslint-disable-next-line no-return-await
      return await fetch(
        walletApi() + "/serviceapi/api/v1/showService/getOwnShowAccount",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: JSON.stringify({
              showId: metaId,
            }),
          }),
        }
      )
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          var userInfo = json.result;
          let re =
            /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,16}$/;
          if (userInfo.email && !re.test(userInfo.email)) {
            try {
              userInfo.email = this.MetanetSDK.decryptInfoData(
                "email",
                userInfo.email
              );
            } catch {
              console.log("email_decrypt_error");
            }
          }
          if (userInfo.phone.length > 20) {
            userInfo.phone = this.MetanetSDK.decryptInfoData(
              "phone",
              userInfo.phone
            );
          }
          var handlerId = params.payload ? params.payload.handlerId : null;
          const res = {
            code: 200,
            status: "success",
            data: userInfo,
          };
          if (handlerId) {
            res.handlerId = handlerId;
          }
          parentMessageClient.send("receive-callback", res);
          // return userInfo
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async ecdhEncryptData(params) {
      console.log(params);
      if (!this.isLogined) {
        return this.receiveNotLogin(params.payload.handlerId);
      }
      const accessToken =
        params.payload && params.payload.accessToken
          ? params.payload.accessToken
          : null;
      const isAuthed = await this.authCheck(accessToken);
      if (params.payload.handlerId && (!accessToken || !isAuthed)) {
        return this.receiveNotLogin(params.payload.handlerId);
      }
      const data = JSON.parse(params.payload.data);
      const encryptData = this.MetanetSDK.ecdhEncryptData(
        data.msg,
        data.publickey
      );
      let res;
      if (encryptData) {
        res = {
          code: 200,
          status: "success",
          data: {
            message: "Encrypt data success",
            data: encryptData.toString("hex"),
          },
        };
      } else {
        res = {
          code: 204,
          status: "error",
          data: {
            message: "Encrypt data error",
            data: "",
          },
        };
      }
      console.log("res", res);
      this.receiveCallback(params.payload.handlerId, res);
    },
    async ecdhDecryptData(params) {
      const data = JSON.parse(params.payload.data);
      const decryptData = await this.MetanetSDK.ecdhDecryptData(
        data.msg,
        data.publickey,
        data?.metaDataPublicKey
      );
      let res;
      if (decryptData) {
        res = {
          code: 200,
          status: "success",
          data: {
            message: "Decrypt data success",
            data: decryptData,
          },
        };
      } else {
        res = {
          code: 204,
          status: "error",
          data: {
            message: "Decrypt data error",
            data: "",
          },
        };
      }
      console.log("res", res);
      this.receiveCallback(params.payload.handlerId, res);
    },
    async paytoAddress(params) {
      console.log("paytoParams", params);
      const accessToken =
        params.payload && params.payload.accessToken
          ? params.payload.accessToken
          : null;
      if (!accessToken) return this.handleNotLogined();
      const isAuthed = await this.authCheck(accessToken);
      console.log("isAuthed:", isAuthed);
      if (!isAuthed) {
        Popup.close();
        this.handleNotLogined({
          message: this.$t("loginLostTip"),
          useCurrentWindow: true,
        });
        return false;
      }
      if (params.payload.handlerId && !accessToken) {
        return this.receiveNotLogin(params.payload.handlerId);
      }
      if (!this.riskLimit) {
        await this.initLimit(accessToken);
      }
      if (!this.riskLimit.popupsPay) {
        this.sendError(204, "No transfer permission");
      }
      const data = JSON.parse(params.payload.data);
      console.log("data", data);
      // if (!Array.isArray(data.to)) {
      //   return this.sendError(204, 'params to must be array')
      // }
      const appname = localStorage.getItem("appName");
      const result = await this.MetanetSDK.sendMoney({
        to: data.to,
        currency: data.currency,
        opReturn: ["show", "web", appname, this.authSettings.clientId],
        checkOnly: data.checkOnly,
        amount: data.amount,
      });
      console.log("result", result);
      this.receiveCallback(params.payload.handlerId, result);
    },
    receiveCallback(handlerId, params) {
      if (!handlerId) return;
      if (typeof params !== "object") {
        params = {};
      }
      params.handlerId = handlerId;
      parentMessageClient.send("receive-callback", params);
    },
    sendError(code, message) {
      const res = {
        code: code,
        status: "error",
        data: {
          message: message,
        },
      };
      parentMessageClient.send("error.common", res);
    },
    async getFTList(params) {
      const data = await this.MetanetSDK.ftList(
        this.MetanetSDK.rootAddress.address
      );
      let res;
      if (data) {
        res = {
          code: 200,
          status: "success",
          data,
        };
      } else {
        res = {
          code: 204,
          status: "error",
        };
      }
      this.receiveCallback(params.payload.handlerId, res);
    },

    /**
     * functionObj 属性：
     *    useDataStructure
     *    errorCode:"错误 code",
          subscribeName:"订阅事件名称",
          errorMessage:"错误提示信息",
          successMessage:"成功提示信息",
          dataField: "指定函数参数使用的字段"
          resultField: "指定返回结果使用字段"
     */
    async initHandle() {
      const functionObj = {
        swapreqswapargs: {},
        estimateSwapToken2Amount: {},
        estimateSwapToken1Amount: {},
        isSupportedFt: {},
        transferFT: {},
        swapft: {},
        Addliq: {},
        withdrawliq: {},
        genesisNFT: {},
        issueNFT: {},
        getBalance: {},
        nftCancel: {},
        nftSell: {},
        nftBuy: {},
        preFetchSignRaw: {},
        sendRedEnvelope: {},
        getRedEnvelope: {},
        resumeTransaction: {},
        createBrfcProtocolNode: {
          successMessage: "createBrfcProtocolNode success",
          useDataStructure: true,
          resultField: "data",
        },
        nftList: {
          dataField: "address",
        },
        signMessage: {
          subscribeName: "sign-messgae",
        },
        getPathInfo: {
          subscribeName: "get-path-info",
          dataField: "path",
        },
        decryptProtocolData: {
          subscribeName: "decrypt-protocol-data",
          errorMessage: "Decrypt protocol data error",
          successMessage: "Decrypt protocol data success",
          useDataStructure: true,
        },
        eciesEncryptData: {
          subscribeName: "ecies-encrypt-data",
          errorMessage: "ecies encrypt data error",
          successMessage: "ecies encrypt data success",
          useDataStructure: true,
        },
        eciesDecryptData: {
          subscribeName: "ecies-decrypt-data",
          errorMessage: "ecies decrypt data error",
          successMessage: "ecies decrypt data success",
          useDataStructure: true,
        },
        transferNFT: {},
        parseGenesTxId: {}, // 根据genesisTxId 解析 codehash, genesis,sensibleId
      };
      for (let item in functionObj) {
        this[item] = async (params) => {
          let res;
          let dataStructure = {
            message: functionObj[item]?.successMessage,
          };
          try {
            const data = await this.MetanetSDK[item](
              functionObj[item].dataField
                ? params.payload[functionObj[item].dataField]
                : params.payload?.data
            );
            let resultFieldData = "";
            if (functionObj[item]?.useDataStructure) {
              resultFieldData = functionObj[item]?.resultField
                ? data[functionObj[item]?.resultField]
                : { data };
            }
            res = {
              code: 200,
              status: "success",
              data: functionObj[item]?.useDataStructure
                ? { ...dataStructure, ...resultFieldData }
                : data,
            };
          } catch (err) {
            dataStructure.message = functionObj[item]?.errorMessage;
            res = {
              code: functionObj[item].errorCode || 204,
              status: "error",
              data: functionObj[item]?.useDataStructure
                ? dataStructure
                : {
                    message:
                      err?.data?.message || err?.message || `${item} failed`,
                  },
            };
          }
          this.receiveCallback(params.payload.handlerId, res);
        };
        selfMessageClient.subscribe(
          functionObj[item].subscribeName || item,
          this[item]
        );
      }
    },

    // nft 拍卖
    async nftStartAuction(params) {
      console.log("nftStartAuction", params);
      const payload = params.payload || {};
      const checkOnly = !!payload.checkOnly;
      const useFeeb = payload.useFeeb || sensibleFeeb;
      const feeRate = payload.feeRate || 5;
      let ParentInfo = {};
      try {
        ParentInfo = await this.MetanetSDK.createBrfcProtocolNode({
          nodeName: "NftAuctionCreate",
          brfcId: "b9ab0750cc93",
          path: "/Protocols/NftAuctionCreate",
          needConfirm: checkOnly,
          useFeeb,
        });
      } catch (error) {
        console.error(error);
        return this.receiveCallback(
          params.payload.handlerId,
          this.generateRes(204, "error", {
            message: "生成协议节点失败",
          })
        );
      }
      console.log(ParentInfo);
      const xpubKey = this.MetanetSDK.fundingKey.xpubkey;
      console.log("xpubKey", xpubKey);
      const result = await this.MetanetSDK.getPulicKeyForNewNode(
        xpubKey,
        ParentInfo.data.txId,
        1
      );
      console.log("result", result);
      const pNode = result[0].publicKey;
      const parentAddress = bsv.PublicKey.fromHex(ParentInfo.data.PublicKey)
        .toAddress()
        .toString();
      const addressPathObj = await this.MetanetSDK.getPathWithNetWork(
        parentAddress
      );
      console.log("addressObj", addressPathObj);
      if (!addressPathObj.Address) {
        return console.error(`无法获取 UTXO 地址 ${parentAddress} 的 Path`);
        // return this.sendError(204, `无法获取 UTXO 地址 ${parentAddress} 的 Path`)
      }
      const parentPath = addressPathObj.Path;
      const nodeData = JSON.stringify({
        nft: payload.nft,
        startingPrice: payload.startBsvPrice,
        minBidIncrease: payload.minBidIncrease,
        chargeUnit: "bsv",
        endTimeStamp: payload.endTimeStamp,
      });

      const nft = payload.nft || {};
      console.log("nft data: ", nft);
      const CREATURE = {
        codehash: nft.codehash,
        genesis: nft.genesis,
        tokenIndex: nft.tokenIndex,
      };

      const scriptPlayload = [
        "meta",
        pNode,
        ParentInfo.data.txId,
        metaIdTag.toLowerCase(),
        "NftAuctionCreate-" + pNode.substr(0, 12),
        nodeData,
        "0",
        "1.0.0",
        "text/plain",
        "UTF-8",
      ];

      const { signers, signerSelecteds } =
        await this.MetanetSDK.nftSingRawPrefetch(nft.genesisTxid);
      const nftSigner = new NftSigner({
        signerSelecteds: signerSelecteds,
        signerConfigs: signers,
      });

      const provider = new MetaSVProvider("mainnet", {
        privateKey: metasvServiceSecret,
      });
      // const provider = new SensiblequeryProvider()
      const wallet = LocalWallet.fromWIF(
        this.MetanetSDK.getPathPrivateKey("0/0").toString()
      );
      const sensible = new Sensible(provider, wallet);
      console.log("sensible", sensible);

      let utxos = await this.MetanetSDK.getUtxoFromApi();

      if (utxos.length === 0) {
        // throw new Error("this account have not balance")
        return parentMessageClient.send(
          "error.not-enough-money",
          "this account have not balance"
        );
      }
      const estimateUtxo = [utxos[0]];
      console.log("start Nft Auction param:", {
        nft: CREATURE,
        nftSigner,
        ownerWif: this.MetanetSDK.getPathPrivateKey("0/0").toString(),
        startBsvPrice: parseInt(payload.startBsvPrice),
        endTimeStamp: parseInt(payload.endTimeStamp),
        feeAddress: payload.feeAddress,
        feeRate: parseInt(payload.feeRate),
        utxos: estimateUtxo,
        opreturnData: scriptPlayload,
      });
      let rawtxs = [];
      try {
        const startRes = await sensible.startNftAuction(
          {
            nft: CREATURE,
            nftSigner,
            ownerWif: this.MetanetSDK.getPathPrivateKey("0/0").toString(),
            startBsvPrice: parseInt(payload.startBsvPrice),
            endTimeStamp: parseInt(payload.endTimeStamp),
            feeAddress: payload.feeAddress,
            feeRate: parseInt(feeRate),
            utxos: estimateUtxo,
            opreturnData: scriptPlayload,
          },
          { onlyEstimateFee: true }
        );
        console.log("startRes: ", startRes);
        const fee = startRes.fee + 1000;
        if (checkOnly) {
          const res = this.generateRes(200, "success", {
            amount: Math.ceil(fee),
          });
          return this.receiveCallback(params.payload.handlerId, res);
        }

        // console.log('fetchFeeUtxosParams: ', { parentAddress, parentPath, estimateSatoshis: fee, utxos, utxoMaxCount: 1, checkOnly, useFeeb })
        // const utxosRes = await this.MetanetSDK.fetchFeeUtxos({ parentAddress, parentPath, estimateSatoshis: fee, utxos, utxoMaxCount: 1, checkOnly, useFeeb })
        // utxos = utxosRes.utxos
        // console.log('fetchFeeUtxos', utxosRes)
        /* if (!utxos || !utxos.length) { */
        /* return parentMessageClient.send('error.not-enough-money', 'this account have not enough balance') */
        /* } */

        // const transferTxid = await this.MetanetSDK.oytransfer(parentAddress, fee, true)
        const receivers = [{ address: parentAddress, amount: fee }];
        const txResult = await this.MetanetSDK.oytransfer(receivers, true);
        await this.MetanetSDK.sendMetaSvTx(txResult.txHex);
        // await sleep(3000)
        const transferTxid = txResult.txid;

        console.log("transferTxId", transferTxid);
        const addressType = parentPath.split("/")[0];
        const addressIndex = parentPath.split("/")[1];

        const wif = bsv.HDPrivateKey.fromString(this.MetanetSDK.xprivKey)
          .deriveChild(+addressType)
          .deriveChild(+addressIndex)
          .privateKey.toString();

        const iUtxos = [
          {
            txId: transferTxid,
            outputIndex: 0,
            satoshis: fee,
            address: parentAddress,
            wif,
          },
        ];

        console.log("iUtxos: ", iUtxos);

        const startRes2 = await sensible.startNftAuction(
          {
            nft: CREATURE,
            nftSigner,
            ownerWif: this.MetanetSDK.getPathPrivateKey("0/0").toString(),
            startBsvPrice: parseInt(payload.startBsvPrice),
            endTimeStamp: parseInt(payload.endTimeStamp),
            feeAddress: payload.feeAddress,
            feeRate: parseInt(feeRate),
            utxos: iUtxos,
            opreturnData: scriptPlayload,
          },
          { noBroadcast: true }
        );
        console.log("startRes2: ", startRes2);
        rawtxs = startRes2.rawtxs;
      } catch (error) {
        console.error(error);
        return this.receiveCallback(
          params.payload.handlerId,
          this.generateRes(204, "error", {
            message: "创建拍卖合约失败",
          })
        );
      }
      const txIds = [];
      try {
        for (let rawTx of rawtxs) {
          const txid = await this.MetanetSDK.sendMetaSvTx(rawTx);
          txIds.push(txid);
        }
        return this.receiveCallback(
          params.payload.handlerId,
          this.generateRes(200, "success", {
            data: {
              txIds: txIds,
            },
          })
        );
      } catch (error) {
        return this.receiveCallback(
          params.payload.handlerId,
          this.generateRes(204, "error", {
            message: "广播失败",
          })
        );
      }
    },

    async nftAuctionBid(params) {
      console.log("nftAuctionBid", params);
      const payload = params.payload || {};
      const checkOnly = !!payload.checkOnly;
      const useFeeb = payload.useFeeb || sensibleFeeb;
      const bsvBidPrice = payload.bsvBidPrice || 0;
      const lastBsvBidPrice = payload.lastBidPrice || 0;

      let ParentInfo = {};
      try {
        ParentInfo = await this.MetanetSDK.createBrfcProtocolNode({
          nodeName: "NftAuctionBid",
          brfcId: "df2a57036fbe",
          path: "/Protocols/NftAuctionBid",
          needConfirm: checkOnly,
          useFeeb,
        });
      } catch (error) {
        console.error(error);
        return this.receiveCallback(
          params.payload.handlerId,
          this.generateRes(204, "error", {
            data: {
              message: "生成协议节点失败",
            },
          })
        );
      }
      const result = await this.MetanetSDK.getPulicKeyForNewNode(
        this.MetanetSDK.fundingKey.xpubkey,
        ParentInfo.data.txId,
        1
      );
      const pNode = result[0].publicKey;
      const parentAddress = bsv.PublicKey.fromHex(ParentInfo.data.PublicKey)
        .toAddress()
        .toString();
      const addressPathObj = await this.MetanetSDK.getPathWithNetWork(
        parentAddress
      );
      console.log("addressObj", addressPathObj);
      if (!addressPathObj.Address) {
        return console.error(`无法获取 UTXO 地址 ${parentAddress} 的 Path`);
        // return this.sendError(204, `无法获取 UTXO 地址 ${parentAddress} 的 Path`)
      }
      const parentPath = addressPathObj.Path;

      const nft = payload.nft || {};
      const nodeData = JSON.stringify({
        nft,
        bidPrice: payload.bsvBidPrice,
        chargeUnit: "bsv",
        nftAuctionId: payload.nftAuctionId,
      });

      const scriptPlayload = [
        "meta",
        pNode,
        ParentInfo.data.txId,
        metaIdTag,
        "NftAuctionBid-" + pNode.substr(0, 12),
        nodeData,
        "0",
        "1.0.0",
        "text/plain",
        "UTF-8",
      ];

      const CREATURE = {
        codehash: nft.codehash,
        genesis: nft.genesis,
        tokenIndex: nft.tokenIndex,
      };

      const { signers, signerSelecteds } =
        await this.MetanetSDK.nftSingRawPrefetch(nft.genesisTxid);
      const nftSigner = new NftSigner({
        signerSelecteds: signerSelecteds,
        signerConfigs: signers,
      });

      // const provider = new MetaSVProvider({
      //   network: this.MetanetSDK.network,
      //   privateKey: metasvServiceSecret
      // })
      const provider = new MetaSVProvider("mainnet", {
        privateKey: metasvServiceSecret,
      });
      // const provider = new SensiblequeryProvider()
      const wallet = LocalWallet.fromWIF(
        this.MetanetSDK.getPathPrivateKey("0/0").toString()
      );
      const sensible = new Sensible(provider, wallet);
      console.log("sensible", sensible);

      let utxos = await this.MetanetSDK.getUtxoFromApi();

      if (utxos.length === 0) {
        // throw new Error("this account have not balance")
        return parentMessageClient.send(
          "error.not-enough-money",
          "this account have not balance"
        );
      }
      const estimateUtxo = [utxos[0]];

      console.log("开始拍卖");
      let rawTx = "";
      try {
        let { fee } = await sensible.bidInNftAuction(
          {
            nft: CREATURE,
            nftSigner,
            bidderWif: this.MetanetSDK.getPathPrivateKey("0/0").toString(),
            bsvBidPrice: parseInt(payload.bsvBidPrice),
            utxos: estimateUtxo,
            opreturnData: scriptPlayload,
          },
          { onlyEstimateFee: true }
        );
        // console.log('fee lastprice', fee, lastBsvBidPrice)
        // fee += (parseInt(lastBsvBidPrice) + 1000)
        fee += 1000;
        // console.log('fee + lastPrice', fee)

        if (checkOnly) {
          const res = this.generateRes(200, "success", {
            amount: Math.ceil(fee),
          });
          console.log(res);
          return this.receiveCallback(params.payload.handlerId, res);
        }

        utxos = (
          await this.MetanetSDK.fetchFeeUtxos({
            parentAddress,
            parentPath,
            estimateSatoshis: fee,
            utxos,
            utxoMaxCount: 1,
            checkOnly,
            useFeeb,
          })
        ).utxos;

        console.log("bidInNftAuction params: ", {
          nft: CREATURE,
          nftSigner,
          bidderWif: this.MetanetSDK.getPathPrivateKey("0/0").toString(),
          bsvBidPrice: parseInt(payload.bsvBidPrice),
          utxos,
          opreturnData: scriptPlayload,
        });
        const { rawtx } = await sensible.bidInNftAuction(
          {
            nft: CREATURE,
            nftSigner,
            bidderWif: this.MetanetSDK.getPathPrivateKey("0/0").toString(),
            bsvBidPrice: parseInt(payload.bsvBidPrice),
            utxos,
            opreturnData: scriptPlayload,
          },
          { noBroadcast: true }
        );
        console.log("rawTx", rawtx);
        rawTx = rawtx;
      } catch (error) {
        console.error(error);
        return this.receiveCallback(
          params.payload.handlerId,
          this.generateRes(204, "error", {
            data: {
              txId: "",
              message: "创建出价合约失败 " + error,
            },
          })
        );
      }

      try {
        const txId = await this.MetanetSDK.sendMetaSvTx(rawTx);
        return this.receiveCallback(
          params.payload.handlerId,
          this.generateRes(200, "success", {
            data: {
              txId: txId,
            },
          })
        );
      } catch (error) {
        return this.receiveCallback(
          params.payload.handlerId,
          this.generateRes(204, "error", {
            data: {
              txId: "",
              message: "出价失败 " + error,
            },
          })
        );
      }
    },

    async nftAuctionWithDraw(params) {
      const payload = params.payload || {};
      const checkOnly = !!payload.checkOnly;
      const useFeeb = payload.useFeeb || sensibleFeeb;
      const ParentInfo = await this.MetanetSDK.createBrfcProtocolNode({
        nodeName: "NftAuctionWithdraw",
        brfcId: "cbf59de87520",
        path: "/Protocols/NftAuctionWithdraw",
        needConfirm: checkOnly,
        useFeeb,
      });
      const result = await this.MetanetSDK.getPulicKeyForNewNode(
        this.MetanetSDK.fundingKey.xpubkey,
        ParentInfo.data.txId,
        1
      );
      const pNode = result[0].publicKey;
      const parentAddress = bsv.PublicKey.fromHex(ParentInfo.data.PublicKey)
        .toAddress()
        .toString();
      const addressPathObj = await this.MetanetSDK.getPathWithNetWork(
        parentAddress
      );
      if (!addressPathObj.Address) {
        throw new Error(`无法获取 UTXO 地址 ${parentAddress} 的 Path`);
      }
      const parentPath = addressPathObj.Path;
      const nft = payload.nft || {};

      const data = JSON.stringify({
        nft,
        nftAuctionId: payload.nftAuctionId,
      });
      const scriptPlayload = [
        "meta",
        pNode,
        ParentInfo.data.txId,
        metaIdTag.toLowerCase(),
        "NftAuctionWithdraw-" + pNode.substr(0, 12),
        data,
        "0",
        "1.0.0",
        "text/plain",
        "UTF-8",
      ];

      let CREATURE = {
        codehash: nft.codehash,
        genesis: nft.genesis,
        tokenIndex: nft.tokenIndex,
      };

      // const provider = new MetaSVProvider({
      //   privateKey: metasvServiceSecret
      // })
      const provider = new MetaSVProvider("mainnet", {
        privateKey: metasvServiceSecret,
      });
      // const provider = new SensiblequeryProvider();
      let wallet = LocalWallet.fromWIF(
        this.MetanetSDK.getPathPrivateKey("0/0").toString()
      );
      let sensible = new Sensible(provider, wallet);

      let utxos = await this.MetanetSDK.getUtxoFromApi();
      if (utxos.length == 0) {
        throw new Error("this account have not balance");
      }

      const estimateUtxo = [utxos[0]];

      const { signers, signerSelecteds } =
        await this.MetanetSDK.nftSingRawPrefetch(nft.genesisTxid);
      const nftSigner = new NftSigner({
        signerSelecteds: signerSelecteds,
        signerConfigs: signers,
      });

      let { fee } = await sensible.withdrawInNftAuction(
        {
          nft: CREATURE,
          nftSigner,
          ownerWif: this.MetanetSDK.getPathPrivateKey("0/0").toString(),
          utxos: estimateUtxo,
          opreturnData: scriptPlayload,
        },
        { onlyEstimateFee: true }
      );

      fee = Math.ceil(fee + 1000);
      if (checkOnly) {
        const res = this.generateRes(200, "success", {
          amount: Math.ceil(fee),
        });
        return this.receiveCallback(params.payload.handlerId, res);
      }

      const feeUtxosRes = await this.MetanetSDK.fetchFeeUtxos({
        parentAddress,
        parentPath,
        estimateSatoshis: fee,
        utxos,
        utxoMaxCount: 1,
        checkOnly,
        useFeeb,
      });
      console.log("feeUtxos: ", feeUtxosRes);
      utxos = feeUtxosRes.utxos;

      const { rawtxs } = await sensible.withdrawInNftAuction(
        {
          nft: CREATURE,
          nftSigner,
          ownerWif: this.MetanetSDK.getPathPrivateKey("0/0").toString(),
          utxos: utxos,
          opreturnData: scriptPlayload,
        },
        { noBroadcast: true }
      );

      let txIds = [];
      try {
        for (let rawTx of rawtxs) {
          const txid = await this.MetanetSDK.sendMetaSvTx(rawTx);
          txIds.push(txid);
        }
        return this.receiveCallback(
          params.payload.handlerId,
          this.generateRes(200, "success", {
            data: {
              txIds: txIds,
            },
          })
        );
      } catch (error) {
        console.error(error);
        return this.receiveCallback(
          params.payload.handlerId,
          this.generateRes(204, "error", {
            data: {
              txId: "",
              message: "广播失败",
            },
          })
        );
      }
    },

    async getInviteCode(params) {
      if (!this.isLogined) {
        return this.receiveNotLogin(params.payload.handlerId);
      }
      const defaultRes = {
        code: 200,
        status: "success",
        data: {
          inviteCode: null,
        },
      };
      const localCode = localStorage.getItem("invateCode");
      if (localCode) {
        return this.receiveCallback(params.payload.handlerId, {
          ...defaultRes,
          data: {
            inviteCode: localCode,
          },
        });
      }
      const res = await getInviteCode();
      console.log("get InviteCode", res.promotionCode);
      if (res.promotionCode) {
        return this.receiveCallback(params.payload.handlerId, {
          code: 200,
          status: "success",
          data: {
            inviteCode: res.promotionCode,
          },
        });
      } else {
        return this.receiveCallback(params.payload.handlerId, {
          code: 204,
          status: "error",
          data: {
            message: "Get inviteCode error",
          },
        });
      }
      // return this.receiveCallback(params.payload.handlerId, res);
    },

    generateRes(code, status, data) {
      return {
        code: code,
        status: status,
        data: data,
      };
    },

    /* // nft 转账
    transferNft(params) {
      return new Promise(async (resolve) => {
        try {
          const payload = params.payload || {};
          const res = await this.$store.state.metanet.transferNFT(
            payload.tokenIndex,
            payload.receiveAddress,
            payload.codehash,
            payload.genesis,
            payload.genesisTxid
          );
          if (res) {
            return this.receiveCallback(params.payload.handlerId, res);
          }
        } catch (err) {
          return this.receiveCallback(params.payload.handlerId, err);
        }
      });
    }, */
  },
  created() {
    console.log("main_frame created11");
    // this.getInviteCode()
    this.$utils
      .getMoneyRate()
      .then((res) => {
        this.$store.state.moneyRate = res.price;
      })
      .catch((err) => {
        return this.$message.error(this.$t("Error") + err);
      });
    if (useTestNet()) {
      this.BASE_URL = "https://gray2scale.showmoney.io/";
    }
    if (!this.isLogined) {
      this.sendError(201, "User authentication failed");
    } else {
      const userToken =
        JSON.parse(localStorage.getItem("localuserData")).token || "";
      checkUserToken(userToken)
        .then((res) => {
          if (res.code === 500) {
            this.sendError(201, "User authentication expired");
          }
        })
        .catch(() => {
          /* localStorage.clear(); */
          /* sessionStorage.clear(); */
          this.sendError(204, "Check user token error");
        });
    }
    // let time = this.$cookie.get('__Injector-time') || ''
    // if (!time) {
    //   console.log('删除密码')
    //   localStorage.removeItem('__Secure-SSID')
    //   return this.sendError(201, 'User authentication failed')
    // }
    // TODO:暂时不判断
    // if (this.isLogined && window.ShowmoneyMetanetSDK) {
    if (this.isLogined) {
      try {
        this.initSDK();
      } catch (error) {
        this.sendError(204, error.message || "SDK init fault");
      }
    } else {
      console.log("初始化错误");
      this.sendError(201, "User authentication failed");
    }
    // 消息监听
    selfMessageClient.start();
    this.initHandle();
    selfMessageClient.subscribe(
      "confirmed.create-node",
      this.handleConfirmedCreateNode
    );
    selfMessageClient.subscribe("create-node", this.createNode);
    selfMessageClient.subscribe("get-user-info", this.getUserInfo);
    selfMessageClient.subscribe("get-invitecode", this.getInviteCode);

    selfMessageClient.subscribe("ecdh-encrypt-data", this.ecdhEncryptData);
    selfMessageClient.subscribe("ecdh-decrypt-data", this.ecdhDecryptData);

    selfMessageClient.subscribe("paytoAddress", this.paytoAddress);
    selfMessageClient.subscribe("send-options", this.getOptions);
    selfMessageClient.subscribe("make-tx", this.makeTx);
    // selfMessageClient.subscribe("logout", this.logout);

    // token 转账
    selfMessageClient.subscribe("get-ftlist", this.getFTList);
    // nft 拍卖
    selfMessageClient.subscribe("nft-start-auction", this.nftStartAuction);
    selfMessageClient.subscribe("nft-auction-bid", this.nftAuctionBid);
    selfMessageClient.subscribe(
      "nft-auction-withdraw",
      this.nftAuctionWithDraw
    );
    /* // nft 转账
    selfMessageClient.subscribe("nft-transfer", this.transferNft); */
  },
};
</script>
<style lang="scss">
body {
  &::-webkit-scrollbar {
    display: none;
  }
}
#showmoney-popup {
  font-size: 16px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.6);
}
#showmoney-popup.active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sm-popup-box {
  width: 100%;
  overflow-y: auto;
  padding-bottom: 45px;
  background: #fff;
  border-top: 0.8em solid #2196f3;
}
.error-popup .sm-popup-box {
  border-top: 0.8em solid #ff5252;
}
.sm-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.sm-popup-title {
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.25;
  color: #222;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  padding: 1em;
}
.sm-popup-close {
  cursor: pointer;
  font-family: inherit;
  font-size: 120%;
  line-height: 1.8;
  border: 0;
  background: transparent;
  position: absolute;
  right: 0.5em;
  top: 0;
}
.sm-popup-close:before {
  content: "\2715";
}
.sm-popup-content {
  margin: 1.5em 1em;
  line-height: 1.5;
  color: #333;
  .tip {
    font-size: 12px;
    text-align: center;
    margin-top: 16px;
    color: #888;
  }
}
.sm-popup-footer {
  display: flex;
  border-top: 1px solid #eee;
  justify-content: space-between;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.sm-popup-btn {
  font-size: 0.875em;
  padding: 1em;
  border-radius: 0.25em;
  border: 0;
  cursor: pointer;
  background: transparent;
  will-change: transform;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
  line-height: 1.15;
  display: inline-block;
  width: 49.99%;
}
.sm-popup-btn1 {
  color: #999;
  border-right: 1px solid #eee;
}
.confirm-popup .amount-btn {
  background: #eab300;
  margin: 0 auto;
  padding: 0.5em;
  border-radius: 100px;
  text-align: center;
  width: 70%;
  color: #fff;
  font-size: 1.2em;
}
.confirm-popup .checkbox-group {
  text-align: center;
  margin: 1em 0;
  color: #888;
  font-size: 0.8em;
  cursor: pointer;
}
.confirm-popup .checkbox-group input {
  margin-right: 0.5em;
}
.confirm-popup .amount-btn span {
  font-size: 0.75em;
}
.confirm-popup .checkbox-group input:checked + label {
  color: #eab300;
}

@keyframes smslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}
@keyframes smfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes smfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes smslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}
</style>
